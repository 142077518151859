import "./Register.css";

import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "./firebase";

import appLogo from "./images/WTLAY-title-graphic.png";
import ppi from "./images/PPI logo_2022_white_background.webp";
import styled from "styled-components";
import { useAuthState } from "react-firebase-hooks/auth";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/home", { replace: true });
  }, [user, loading]);
  return (
    <MainContainer>
      <LoginContainer>
        <LogoContainer>
          <PPILOGO src={ppi}></PPILOGO>
        </LogoContainer>
        <input
          type="text"
          className="register__textBox"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Full Name"
        />
        <input
          type="text"
          className="register__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="register__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <LoginButton onClick={register}>Register</LoginButton>
        {/* <button
          className="register__btn register__google"
          onClick={signInWithGoogle}
        >
          Register with Google
        </button> */}
        <div>
          Already have an account? <Link to="/">Login</Link> now.
        </div>
      </LoginContainer>
    </MainContainer>
  );
}
export default Register;

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #25364c;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #25364c;
  width: 50%;

  padding: 30px;
  border-radius: 20px;
  background-color: white;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const PPILOGO = styled.img`
  width: 80%;
  margin-right: 10px;
`;

const APPLOGO = styled.img`
  width: 30%;
  margin: 5px;
`;

const LoginButton = styled.button`
  border-radius: 10px;
  color: white;
  background-color: #25364c;
  height: 40px;
  font-size: 18px;
`;

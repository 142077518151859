import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { auth, db, logout } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

import Header from "../components/Header";
import LogRocket from "logrocket";
import appLogo from "../images/WTLAY-title-graphic.png";
import ppi from "../images/PPI logo_2022_white_background.webp";
import styled from "styled-components";
import { useAuthState } from "react-firebase-hooks/auth";

function PPIHome() {
  const navigate = useNavigate();

  return (
    <MainContainer>
      <Card>
        <Link to="/wtlay" style={{ textDecoration: "none" }}>
          <CardImage src={require("../images/wtlay_logo.png")} />
        </Link>
      </Card>
      <Card>
        <Link to="/abc" style={{ textDecoration: "none" }}>
          <CardImage src={require("../images/abc_logo_1.png")} />
        </Link>
      </Card>
    </MainContainer>
  );
}

export default PPIHome;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  height: 160px;
  width: 200px;
  border: 1px solid #2192bd;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;

  @media (max-width: 768px) {
    height: 160px;
    width: 200px;
    margin: 10px;
  }

  @media (max-width: 250px) {
    height: 160px;
    width: 150px;
    margin: 10px;
  }
`;

const LinkText = styled.p`
  font-size: 22px;
  color: #25364c;
  text-decoration: none;
  text-align: center;
`;

const CardImage = styled.img`
  border-radius: 10px;

  @media (max-width: 768px) {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 250px) {
    max-width: 100%;
    height: auto;
  }
`;

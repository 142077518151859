//import "react-buzzfeed-quiz/lib/styles.css";

// import {
//   Legend,
//   PolarAngleAxis,
//   PolarGrid,
//   PolarRadiusAxis,
//   Radar,
//   RadarChart,
//   ResponsiveContainer,
// } from "recharts";
import React, { Component, useRef, useState } from "react";
import { auth, db, logout } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import './QuizStyles.css';

import BackgroundImage from "../images/taxi.png";
import { BuzzFeedQuiz } from "react-buzzfeed-quiz";
import FirstResultImage from "../images/taxi.png";
import ProfilePhoto from "../images/taxi.png";
import PropTypes from "prop-types";
import ResponseImage from "../images/taxi.png";
import { ResponsiveRadar } from "@nivo/radar";
import SecondResultImage from "../images/taxi.png";
import gridImage from "../images/WTLAY-grid-with-labels.png";
import logo from "../images/WTLAY-title-graphic.png";
import quadrant1Image from "../images/Quadrant-01.png";
import quadrant2Image from "../images/Quadrant-02.png";
import quadrant3Image from "../images/Quadrant-03.png";
import quadrant4Image from "../images/Quadrant-04.png";
import styled from "styled-components";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

function Questions() {
  const [user, loading, error] = useAuthState(auth);

  const [answers, setAnswers] = useState([]);
  const command = useRef(0);
  const control = useRef(0);
  const collaborate = useRef(0);
  const connect = useRef(0);

  const [commandTotal, setCommandTotal] = useState(0);
  const [controlTotal, setControlTotal] = useState(0);
  const [collaborateTotal, setCollaborateTotal] = useState(0);
  const [connectTotal, setConnectTotal] = useState(0);

  const [showGraph, setShowGraph] = useState(false);

  const [renderQuadrant1, setRenderQuadrant1] = useState(false);
  const [renderQuadrant2, setRenderQuadrant2] = useState(false);
  const [renderQuadrant3, setRenderQuadrant3] = useState(false);
  const [renderQuadrant4, setRenderQuadrant4] = useState(false);
  const [color, setColor] = useState("#FFFF00");

  const data = [
    {
      subject: "Collaborate",
      Total: collaborateTotal,
      fullMark: 25,
      color: "#fff",
    },
    { subject: "Connect", Total: connectTotal, fullMark: 25 },
    { subject: "Control", Total: controlTotal, fullMark: 25 },
    { subject: "Command", Total: commandTotal, fullMark: 25 },
  ];

  const theme = {
    fontSize: 2,
    textColor: "#25364c",
    axis: {
      textColor: "#eee",
      fontSize: "25px",
      tickColor: "#eee",
    },
    grid: {
      stroke: "#888",
      strokeWidth: 1,
    },
    legend: { text: { fill: "#fff" } },
  };

  function renderData() {
    console.log("Render Data");
    setShowGraph(true);

    console.log(answers);

    answers.forEach((answer, index) => {
      console.log(answer);
      // console.log(answer.question);
      // console.log(answer.resultID);

      if (
        answer.question === 0 ||
        answer.question === 2 ||
        answer.question === 4 ||
        answer.question === 6
      ) {
        command.current = command.current + answer.resultID;
        console.log("QUESTION " + answer.question);

        // Handle Command
        console.log("Handle Command");
        console.log("command: " + command.current);
        console.log("control: " + control.current);
        console.log("collaborate: " + collaborate.current);
        console.log("connect: " + connect.current);
      } else if (
        answer.question === 1 ||
        answer.question === 3 ||
        answer.question === 5 ||
        answer.question === 7
      ) {
        control.current = control.current + answer.resultID;
        console.log("QUESTION " + answer.question);
        console.log("Handle Control");
        console.log("command: " + command.current);
        console.log("control: " + control.current);
        console.log("collaborate: " + collaborate.current);
        console.log("connect: " + connect.current);
      } else if (
        answer.question === 8 ||
        answer.question === 10 ||
        answer.question === 12 ||
        answer.question === 14
      ) {
        collaborate.current = collaborate.current + answer.resultID;
        console.log("QUESTION " + answer.question);
        console.log("Handle Collaborate");
        console.log("command: " + command.current);
        console.log("control: " + control.current);
        console.log("collaborate: " + collaborate.current);
        console.log("connect: " + connect.current);
      } else if (
        answer.question === 9 ||
        answer.question === 11 ||
        answer.question === 13 ||
        answer.question === 15
      ) {
        connect.current = connect.current + answer.resultID;
        if (answer.question === 15) {
          setCommandTotal(command.current);
          setControlTotal(control.current);
          setCollaborateTotal(collaborate.current);
          setConnectTotal(connect.current);

          var quadrant1 = control.current + command.current;
          var quadrant2 = control.current + connect.current;
          var quadrant3 = command.current + collaborate.current;
          var quadrant4 = collaborate.current + connect.current;

          console.log(
            "Quadrant1 " + quadrant1,
            "Quadrant2 " + quadrant2,
            "Quadrant3 " + quadrant3,
            "Quadrant4 " + quadrant4
          );

          if (
            quadrant1 > quadrant2 &&
            quadrant1 > quadrant3 &&
            quadrant1 > quadrant4
          ) {
            console.log("Quadrant 1 largest");
            setRenderQuadrant1(true);

            setDoc(
              doc(db, "users", user.uid),
              { wtlayResponses: answers, wtlay: { quadrant: 1 } },

              { merge: true }
            ).then(() => {
              console.log("Running after");
            });
          } else if (
            quadrant2 > quadrant1 &&
            quadrant2 > quadrant3 &&
            quadrant2 > quadrant4
          ) {
            console.log("Quadrant 2 largest");
            setRenderQuadrant2(true);

            setDoc(
              doc(db, "users", user.uid),
              { wtlayResponses: answers, wtlay: { quadrant: 2 } },

              { merge: true }
            ).then(() => {
              console.log("Running after");
            });
          } else if (
            quadrant3 > quadrant1 &&
            quadrant3 > quadrant2 &&
            quadrant3 > quadrant4
          ) {
            console.log("Quadrant 3 largest");
            setRenderQuadrant3(true);
            setDoc(
              doc(db, "users", user.uid),
              { wtlayResponses: answers, wtlay: { quadrant: 3 } },

              { merge: true }
            ).then(() => {
              console.log("Running after");
            });
          } else if (
            quadrant4 > quadrant1 &&
            quadrant4 > quadrant2 &&
            quadrant4 > quadrant3
          ) {
            console.log("Quadrant 4 largest");
            setRenderQuadrant4(true);
            setDoc(
              doc(db, "users", user.uid),
              { wtlayResponses: answers, wtlay: { quadrant: 4 } },

              { merge: true }
            ).then(() => {
              console.log("Running after");
            });
            setColor("#38B216");
          }
          window.scrollTo(0, 0);
        }
        console.log("QUESTION " + answer.question);
        console.log("Handle Connect");
        console.log("command: " + command.current);
        console.log("control: " + control.current);
        console.log("collaborate: " + collaborate.current);
        console.log("connect: " + connect.current);
      }
    });
  }

  var quadrant = (
    <QuadrantsContainer>
      <TitleMain>
        <UnderlinedText>
          Your Predominant Leadership Style is…in the Middle.
        </UnderlinedText>
        <RegularText>
          Interestingly, your responses place you equally in all four quadrants.
        </RegularText>
        <RegularText>
          To offer perspective, we’ve provided the attributes, conditions, and
          considerations for each Quadrant below…
        </RegularText>
      </TitleMain>
      <QuadrantContainer>
        <TopContainer>
          <GraphicContainer>
            <QuadrantImage src={quadrant1Image}></QuadrantImage>
          </GraphicContainer>
          <TitleContainer>
            <Title>Quadrant I: Conventional BOSS</Title>
          </TitleContainer>
        </TopContainer>
        <BottomContainer>
          <RegularText>
            When your predominant leadership characteristics lie in Quadrant I,
            your tendencies are to command & control your Team Members.
          </RegularText>
          <Subtitle>This leadership style can be appropriate:</Subtitle>
          <RegularList>
            <RegularListItem>
              During the initial stages of ‘righting the ship’ of significant
              dysfunction
            </RegularListItem>
            <RegularListItem>
              When acute conditions require swift decision and action
            </RegularListItem>
            <RegularListItem>
              When disciplinary action is needed
            </RegularListItem>
          </RegularList>
          <Subtitle>Cautions & Considerations for the Future:</Subtitle>
          <RegularList>
            <RegularListItem>
              Consistently{" "}
              <EmphasizedText>commanding & controlling</EmphasizedText> during
              ‘normal’ workflows & interactions tends to alienate you from your
              Team Members. This often leads to reduced communication, lower
              trust, and creates a work environment of compliance
            </RegularListItem>
            <RegularListItem>
              While necessary at times (as indicated above) Quadrant I
              Leadership is diametrically opposed to Quadrant IV-
              <EmphasizedText>Truly Human LEADERSHIP</EmphasizedText>.{" "}
              <EmphasizedText>Truly Human LEADERS</EmphasizedText> generate a
              work environment of Desire, which is the{" "}
              <UnderlinedText>only</UnderlinedText> way to achieve sustainable
              above-expectation choices, actions, & behaviors by your Team
              Members.{" "}
              <EmphasizedText>Connection & Collaboration</EmphasizedText>, the
              characteristics of Quadrant IV LEADERSHIP, produce best outcomes &
              maximum results. As such, they will be most highly valued into the
              future.
            </RegularListItem>
            <RegularListItem>
              If you are predominantly a Quadrant I Leader, you should take
              action to move more and more into Quadrant IV. Resources listed at
              the bottom of the page will help you make the transition.
            </RegularListItem>
          </RegularList>
        </BottomContainer>
      </QuadrantContainer>
      <QuadrantContainer>
        <TopContainer>
          <GraphicContainer>
            <QuadrantImage src={quadrant2Image}></QuadrantImage>
          </GraphicContainer>
          <TitleContainer>
            <Title>Quadrant II: Committee Director</Title>
          </TitleContainer>
        </TopContainer>
        <BottomContainer>
          <RegularText>
            When your predominant leadership characteristics lie in Quadrant II,
            your tendencies are to bring people together & control the outcomes.
          </RegularText>
          <Subtitle>This leadership style can be appropriate:</Subtitle>
          <RegularList>
            <RegularListItem>
              During the outset of brainstorming and/or plan development to
              articulate the initial vision
            </RegularListItem>
            <RegularListItem>
              To bring things ‘back on track’ during derailed meetings or
              brainstorming
            </RegularListItem>
            <RegularListItem>
              To stimulate discussion and/or engagement when stalemates occur
            </RegularListItem>
          </RegularList>
          <Subtitle>Cautions & Considerations for the Future:</Subtitle>
          <RegularList>
            <RegularListItem>
              Consistently <EmphasizedText>controlling</EmphasizedText> outcomes
              when Team Members are brought together tends to create apathy and
              even animosity toward group brainstorming or problem-solving. This
              can shut down active questioning and input during group sessions
              and individual interactions.
            </RegularListItem>
            <RegularListItem>
              While appropriate at times (as indicated above) be mindful of PPI
              Precepts <StrongText>3</StrongText> and <StrongText>4</StrongText>
              :
            </RegularListItem>
            <RegularList>
              <RegularListItem>
                <StrongText>#3</StrongText>-
                <EmphasizedText>
                  We come to work wanting to do a good job
                </EmphasizedText>
              </RegularListItem>
              <RegularListItem>
                <StrongText>#4</StrongText>-
                <EmphasizedText>
                  Those who do the work have the answers
                </EmphasizedText>
              </RegularListItem>
            </RegularList>
            <RegularListItem>
              If you have been predominantly in Quadrant II…
              <RegularList>
                <RegularListItem>
                  Reveal to your Team Members what you’ve recognized about your
                  past behaviors (yes- this takes guts).
                </RegularListItem>
                <RegularListItem>
                  Let them know you recognize there’s a better way forward and
                  share this PPI Key Insight:
                </RegularListItem>
              </RegularList>
              <Subtitle>No one of us is as smart as all of us </Subtitle>
            </RegularListItem>
            <RegularListItem>
              Mindfully release control and allow Team Member inputs to prevail
              (as appropriate).
            </RegularListItem>
            <RegularListItem>
              Take action to move more and more into Quadrant IV by releasing
              into and actively promoting{" "}
              <EmphasizedText>collaboration</EmphasizedText> in brainstorming
              and decision-making wherever possible. Resources listed at the
              bottom of the page will help you make the transition.
            </RegularListItem>
          </RegularList>
        </BottomContainer>
      </QuadrantContainer>
      <QuadrantContainer>
        <TopContainer>
          <GraphicContainer>
            <QuadrantImage src={quadrant3Image}></QuadrantImage>
          </GraphicContainer>
          <TitleContainer>
            <Title>Quadrant III: Charismatic General</Title>
          </TitleContainer>
        </TopContainer>
        <BottomContainer>
          <RegularText>
            If you’re a Quadrant III Leader, you tend to inspire{" "}
            <EmphasizedText>collaborative commitment</EmphasizedText>; however,
            you’re clearly ‘in charge’.
          </RegularText>
          <Subtitle>This leadership style can be appropriate:</Subtitle>
          <RegularList>
            <RegularListItem>
              When leading troops into ‘battle’ where casualties are a true
              possibility
            </RegularListItem>
            <RegularListItem>
              When ‘bigger than us’ synchronized commitment is required
            </RegularListItem>
            <RegularListItem>
              When needed to rapidly develop an “us-vs-them” synergy
            </RegularListItem>
          </RegularList>
          <Subtitle>Cautions & Considerations for the Future:</Subtitle>
          <RegularList>
            <RegularListItem>
              The ability to charismatically{" "}
              <EmphasizedText>inspire</EmphasizedText> requires acute ability &
              consistent energy that can be rapidly diminished by external
              events & narratives or perceived ‘cracks in your armor’. And since
              none are perfect, there are always ‘cracks’ awaiting exploitation
              by those who would choose to oppose you.
            </RegularListItem>
            <RegularListItem>
              Being charismatic is often a natural characteristic. If you have
              such abilities, it can be easy to convince yourself you know best
              and then use your ‘power’ to entice others to follow.
            </RegularListItem>

            <RegularListItem>
              The challenge is- you do NOT always know best. You should
              consistently remind yourself of this PPI Key Insight:
              <Subtitle>No one of us is as smart as all of us </Subtitle>
            </RegularListItem>
            <RegularListItem>
              Consciously seek the input of others, including actively
              appointing a ‘devil’s advocate’ to overtly question your decisions
              & motivations.
            </RegularListItem>
            <RegularListItem>
              Actively promote use of the{" "}
              <EmphasizedText>ASK Questions</EmphasizedText> Tool.
            </RegularListItem>
            <RegularListItem>
              Take action to move more and more into Quadrant IV by countering
              any Team Member tendencies to ‘blindly’ follow. Resources listed
              at the bottom of the page will help you make the transition.
            </RegularListItem>
          </RegularList>
        </BottomContainer>
      </QuadrantContainer>
      <QuadrantContainer>
        <TopContainer>
          <GraphicContainer>
            <QuadrantImage src={quadrant4Image}></QuadrantImage>
          </GraphicContainer>
          <TitleContainer>
            <Title>Quadrant IV: Truly Human LEADER</Title>
          </TitleContainer>
        </TopContainer>
        <BottomContainer>
          <RegularText>
            The more your predominant characteristics lie in Quadrant IV, the
            more you will tend to move your work environment toward Desire and
            <EmphasizedText></EmphasizedText> the more rapidly you can grow a
            Culture of One Team with One Goal having One Conversation.
          </RegularText>
          <RegularText>
            When you do so, you will be a force for generating{" "}
            <EmphasizedText>sustainable</EmphasizedText> and
            <EmphasizedText>continually-improving</EmphasizedText> WIN-WIN-WIN
            Outcomes & Results.
          </RegularText>
          <Subtitle>This leadership style can be appropriate:</Subtitle>
          <RegularList>
            <RegularListItem>MOST OF THE TIME</RegularListItem>
          </RegularList>
          <Subtitle>Cautions & Considerations for the Future:</Subtitle>
          <RegularList>
            <RegularListItem>
              Recognize there are times when biasing toward one of the other
              quadrants is appropriate (as explained for each of the other
              Quadrants). Such flexibility is referred to as Situational
              Leadership. <StrongText>Note:</StrongText> A classic work on
              Situational Leadership is The One Minute Manager. A link to the
              book is provided in the Resources Section below.
            </RegularListItem>
            <RegularListItem>
              A potential downfall of{" "}
              <EmphasizedText>Truly Human Leaders</EmphasizedText> is successes
              achieved, which can lead to complacency.
            </RegularListItem>

            <RegularListItem>
              Be aware that Natural Principles are at work in all things,
              including Human & Team Performance. Mindfulness and applied energy
              are required on an ongoing basis to maintain upward trajectory and
              momentum.
            </RegularListItem>
            <RegularListItem>
              Actively learn how to become stronger & more capable as a{" "}
              <EmphasizedText>Truly Human LEADER</EmphasizedText>. Resources
              listed at the bottom of the page will help you strengthen your
              influence.
            </RegularListItem>
          </RegularList>
        </BottomContainer>
      </QuadrantContainer>
    </QuadrantsContainer>
  );

  if (renderQuadrant1) {
    quadrant = (
      <QuadrantsContainer>
        <TitleMain>
          <UnderlinedText>Your Predominant Leadership Style</UnderlinedText>
        </TitleMain>
        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant1Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant I: Conventional BOSS</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              When your predominant leadership characteristics lie in Quadrant
              I, your tendencies are to command & control your Team Members.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>
                During the initial stages of ‘righting the ship’ of significant
                dysfunction
              </RegularListItem>
              <RegularListItem>
                When acute conditions require swift decision and action
              </RegularListItem>
              <RegularListItem>
                When disciplinary action is needed
              </RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                Consistently{" "}
                <EmphasizedText>commanding & controlling</EmphasizedText> during
                ‘normal’ workflows & interactions tends to alienate you from
                your Team Members. This often leads to reduced communication,
                lower trust, and creates a work environment of compliance
              </RegularListItem>
              <RegularListItem>
                While necessary at times (as indicated above) Quadrant I
                Leadership is diametrically opposed to Quadrant IV-
                <EmphasizedText>Truly Human LEADERSHIP</EmphasizedText>.{" "}
                <EmphasizedText>Truly Human LEADERS</EmphasizedText> generate a
                work environment of Desire, which is the{" "}
                <UnderlinedText>only</UnderlinedText> way to achieve sustainable
                above-expectation choices, actions, & behaviors by your Team
                Members.{" "}
                <EmphasizedText>Connection & Collaboration</EmphasizedText>, the
                characteristics of Quadrant IV LEADERSHIP, produce best outcomes
                & maximum results. As such, they will be most highly valued into
                the future.
              </RegularListItem>
              <RegularListItem>
                If you are predominantly a Quadrant I Leader, you should take
                action to move more and more into Quadrant IV. Resources listed
                at the bottom of the page will help you make the transition.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>
        <TitleMain>
          <UnderlinedText>Other Predominant Leadership Styles</UnderlinedText>
        </TitleMain>
        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant2Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant II: Committee Director</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              When your predominant leadership characteristics lie in Quadrant
              II, your tendencies are to bring people together & control the
              outcomes.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>
                During the outset of brainstorming and/or plan development to
                articulate the initial vision
              </RegularListItem>
              <RegularListItem>
                To bring things ‘back on track’ during derailed meetings or
                brainstorming
              </RegularListItem>
              <RegularListItem>
                To stimulate discussion and/or engagement when stalemates occur
              </RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                Consistently <EmphasizedText>controlling</EmphasizedText>{" "}
                outcomes when Team Members are brought together tends to create
                apathy and even animosity toward group brainstorming or
                problem-solving. This can shut down active questioning and input
                during group sessions and individual interactions.
              </RegularListItem>
              <RegularListItem>
                While appropriate at times (as indicated above) be mindful of
                PPI Precepts <StrongText>3</StrongText> and{" "}
                <StrongText>4</StrongText>:
              </RegularListItem>
              <RegularList>
                <RegularListItem>
                  <StrongText>#3</StrongText>-
                  <EmphasizedText>
                    We come to work wanting to do a good job
                  </EmphasizedText>
                </RegularListItem>
                <RegularListItem>
                  <StrongText>#4</StrongText>-
                  <EmphasizedText>
                    Those who do the work have the answers
                  </EmphasizedText>
                </RegularListItem>
              </RegularList>
              <RegularListItem>
                If you have been predominantly in Quadrant II…
                <RegularList>
                  <RegularListItem>
                    Reveal to your Team Members what you’ve recognized about
                    your past behaviors (yes- this takes guts).
                  </RegularListItem>
                  <RegularListItem>
                    Let them know you recognize there’s a better way forward and
                    share this PPI Key Insight:
                  </RegularListItem>
                </RegularList>
                <Subtitle>No one of us is as smart as all of us </Subtitle>
              </RegularListItem>
              <RegularListItem>
                Mindfully release control and allow Team Member inputs to
                prevail (as appropriate).
              </RegularListItem>
              <RegularListItem>
                Take action to move more and more into Quadrant IV by releasing
                into and actively promoting{" "}
                <EmphasizedText>collaboration</EmphasizedText> in brainstorming
                and decision-making wherever possible. Resources listed at the
                bottom of the page will help you make the transition.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>
        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant3Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant III: Charismatic General</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              If you’re a Quadrant III Leader, you tend to inspire{" "}
              <EmphasizedText>collaborative commitment</EmphasizedText>;
              however, you’re clearly ‘in charge’.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>
                When leading troops into ‘battle’ where casualties are a true
                possibility
              </RegularListItem>
              <RegularListItem>
                When ‘bigger than us’ synchronized commitment is required
              </RegularListItem>
              <RegularListItem>
                When needed to rapidly develop an “us-vs-them” synergy
              </RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                The ability to charismatically{" "}
                <EmphasizedText>inspire</EmphasizedText> requires acute ability
                & consistent energy that can be rapidly diminished by external
                events & narratives or perceived ‘cracks in your armor’. And
                since none are perfect, there are always ‘cracks’ awaiting
                exploitation by those who would choose to oppose you.
              </RegularListItem>
              <RegularListItem>
                Being charismatic is often a natural characteristic. If you have
                such abilities, it can be easy to convince yourself you know
                best and then use your ‘power’ to entice others to follow.
              </RegularListItem>

              <RegularListItem>
                The challenge is- you do NOT always know best. You should
                consistently remind yourself of this PPI Key Insight:
                <Subtitle>No one of us is as smart as all of us </Subtitle>
              </RegularListItem>
              <RegularListItem>
                Consciously seek the input of others, including actively
                appointing a ‘devil’s advocate’ to overtly question your
                decisions & motivations.
              </RegularListItem>
              <RegularListItem>
                Actively promote use of the{" "}
                <EmphasizedText>ASK Questions</EmphasizedText> Tool.
              </RegularListItem>
              <RegularListItem>
                Take action to move more and more into Quadrant IV by countering
                any Team Member tendencies to ‘blindly’ follow. Resources listed
                at the bottom of the page will help you make the transition.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>
        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant4Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant IV: Truly Human LEADER</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              The more your predominant characteristics lie in Quadrant IV, the
              more you will tend to move your work environment toward Desire and
              <EmphasizedText></EmphasizedText> the more rapidly you can grow a
              Culture of One Team with One Goal having One Conversation.
            </RegularText>
            <RegularText>
              When you do so, you will be a force for generating{" "}
              <EmphasizedText>sustainable</EmphasizedText> and
              <EmphasizedText>continually-improving</EmphasizedText> WIN-WIN-WIN
              Outcomes & Results.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>MOST OF THE TIME</RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                Recognize there are times when biasing toward one of the other
                quadrants is appropriate (as explained for each of the other
                Quadrants). Such flexibility is referred to as Situational
                Leadership. <StrongText>Note:</StrongText> A classic work on
                Situational Leadership is The One Minute Manager. A link to the
                book is provided in the Resources Section below.
              </RegularListItem>
              <RegularListItem>
                A potential downfall of{" "}
                <EmphasizedText>Truly Human Leaders</EmphasizedText> is
                successes achieved, which can lead to complacency.
              </RegularListItem>

              <RegularListItem>
                Be aware that Natural Principles are at work in all things,
                including Human & Team Performance. Mindfulness and applied
                energy are required on an ongoing basis to maintain upward
                trajectory and momentum.
              </RegularListItem>
              <RegularListItem>
                Actively learn how to become stronger & more capable as a{" "}
                <EmphasizedText>Truly Human LEADER</EmphasizedText>. Resources
                listed at the bottom of the page will help you strengthen your
                influence.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>
      </QuadrantsContainer>
    );
  } else if (renderQuadrant2) {
    quadrant = (
      <QuadrantsContainer>
        <TitleMain>
          <UnderlinedText>Your Predominant Leadership Style</UnderlinedText>
        </TitleMain>
        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant2Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant II: Committee Director</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              When your predominant leadership characteristics lie in Quadrant
              II, your tendencies are to bring people together & control the
              outcomes.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>
                During the outset of brainstorming and/or plan development to
                articulate the initial vision
              </RegularListItem>
              <RegularListItem>
                To bring things ‘back on track’ during derailed meetings or
                brainstorming
              </RegularListItem>
              <RegularListItem>
                To stimulate discussion and/or engagement when stalemates occur
              </RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                Consistently <EmphasizedText>controlling</EmphasizedText>{" "}
                outcomes when Team Members are brought together tends to create
                apathy and even animosity toward group brainstorming or
                problem-solving. This can shut down active questioning and input
                during group sessions and individual interactions.
              </RegularListItem>
              <RegularListItem>
                While appropriate at times (as indicated above) be mindful of
                PPI Precepts <StrongText>3</StrongText> and{" "}
                <StrongText>4</StrongText>:
              </RegularListItem>
              <RegularList>
                <RegularListItem>
                  <StrongText>#3</StrongText>-
                  <EmphasizedText>
                    We come to work wanting to do a good job
                  </EmphasizedText>
                </RegularListItem>
                <RegularListItem>
                  <StrongText>#4</StrongText>-
                  <EmphasizedText>
                    Those who do the work have the answers
                  </EmphasizedText>
                </RegularListItem>
              </RegularList>
              <RegularListItem>
                If you have been predominantly in Quadrant II…
                <RegularList>
                  <RegularListItem>
                    Reveal to your Team Members what you’ve recognized about
                    your past behaviors (yes- this takes guts).
                  </RegularListItem>
                  <RegularListItem>
                    Let them know you recognize there’s a better way forward and
                    share this PPI Key Insight:
                  </RegularListItem>
                </RegularList>
                <Subtitle>No one of us is as smart as all of us </Subtitle>
              </RegularListItem>
              <RegularListItem>
                Mindfully release control and allow Team Member inputs to
                prevail (as appropriate).
              </RegularListItem>
              <RegularListItem>
                Take action to move more and more into Quadrant IV by releasing
                into and actively promoting{" "}
                <EmphasizedText>collaboration</EmphasizedText> in brainstorming
                and decision-making wherever possible. Resources listed at the
                bottom of the page will help you make the transition.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>
        <TitleMain>
          <UnderlinedText>Other Predominant Leadership Styles</UnderlinedText>
        </TitleMain>
        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant1Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant I: Conventional BOSS</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              When your predominant leadership characteristics lie in Quadrant
              I, your tendencies are to command & control your Team Members.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>
                During the initial stages of ‘righting the ship’ of significant
                dysfunction
              </RegularListItem>
              <RegularListItem>
                When acute conditions require swift decision and action
              </RegularListItem>
              <RegularListItem>
                When disciplinary action is needed
              </RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                Consistently{" "}
                <EmphasizedText>commanding & controlling</EmphasizedText> during
                ‘normal’ workflows & interactions tends to alienate you from
                your Team Members. This often leads to reduced communication,
                lower trust, and creates a work environment of compliance
              </RegularListItem>
              <RegularListItem>
                While necessary at times (as indicated above) Quadrant I
                Leadership is diametrically opposed to Quadrant IV-
                <EmphasizedText>Truly Human LEADERSHIP</EmphasizedText>.{" "}
                <EmphasizedText>Truly Human LEADERS</EmphasizedText> generate a
                work environment of Desire, which is the{" "}
                <UnderlinedText>only</UnderlinedText> way to achieve sustainable
                above-expectation choices, actions, & behaviors by your Team
                Members.{" "}
                <EmphasizedText>Connection & Collaboration</EmphasizedText>, the
                characteristics of Quadrant IV LEADERSHIP, produce best outcomes
                & maximum results. As such, they will be most highly valued into
                the future.
              </RegularListItem>
              <RegularListItem>
                If you are predominantly a Quadrant I Leader, you should take
                action to move more and more into Quadrant IV. Resources listed
                at the bottom of the page will help you make the transition.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>

        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant3Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant III: Charismatic General</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              If you’re a Quadrant III Leader, you tend to inspire{" "}
              <EmphasizedText>collaborative commitment</EmphasizedText>;
              however, you’re clearly ‘in charge’.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>
                When leading troops into ‘battle’ where casualties are a true
                possibility
              </RegularListItem>
              <RegularListItem>
                When ‘bigger than us’ synchronized commitment is required
              </RegularListItem>
              <RegularListItem>
                When needed to rapidly develop an “us-vs-them” synergy
              </RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                The ability to charismatically{" "}
                <EmphasizedText>inspire</EmphasizedText> requires acute ability
                & consistent energy that can be rapidly diminished by external
                events & narratives or perceived ‘cracks in your armor’. And
                since none are perfect, there are always ‘cracks’ awaiting
                exploitation by those who would choose to oppose you.
              </RegularListItem>
              <RegularListItem>
                Being charismatic is often a natural characteristic. If you have
                such abilities, it can be easy to convince yourself you know
                best and then use your ‘power’ to entice others to follow.
              </RegularListItem>

              <RegularListItem>
                The challenge is- you do NOT always know best. You should
                consistently remind yourself of this PPI Key Insight:
                <Subtitle>No one of us is as smart as all of us </Subtitle>
              </RegularListItem>
              <RegularListItem>
                Consciously seek the input of others, including actively
                appointing a ‘devil’s advocate’ to overtly question your
                decisions & motivations.
              </RegularListItem>
              <RegularListItem>
                Actively promote use of the{" "}
                <EmphasizedText>ASK Questions</EmphasizedText> Tool.
              </RegularListItem>
              <RegularListItem>
                Take action to move more and more into Quadrant IV by countering
                any Team Member tendencies to ‘blindly’ follow. Resources listed
                at the bottom of the page will help you make the transition.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>
        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant4Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant IV: Truly Human LEADER</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              The more your predominant characteristics lie in Quadrant IV, the
              more you will tend to move your work environment toward Desire and
              <EmphasizedText></EmphasizedText> the more rapidly you can grow a
              Culture of One Team with One Goal having One Conversation.
            </RegularText>
            <RegularText>
              When you do so, you will be a force for generating{" "}
              <EmphasizedText>sustainable</EmphasizedText> and
              <EmphasizedText>continually-improving</EmphasizedText> WIN-WIN-WIN
              Outcomes & Results.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>MOST OF THE TIME</RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                Recognize there are times when biasing toward one of the other
                quadrants is appropriate (as explained for each of the other
                Quadrants). Such flexibility is referred to as Situational
                Leadership. <StrongText>Note:</StrongText> A classic work on
                Situational Leadership is The One Minute Manager. A link to the
                book is provided in the Resources Section below.
              </RegularListItem>
              <RegularListItem>
                A potential downfall of{" "}
                <EmphasizedText>Truly Human Leaders</EmphasizedText> is
                successes achieved, which can lead to complacency.
              </RegularListItem>

              <RegularListItem>
                Be aware that Natural Principles are at work in all things,
                including Human & Team Performance. Mindfulness and applied
                energy are required on an ongoing basis to maintain upward
                trajectory and momentum.
              </RegularListItem>
              <RegularListItem>
                Actively learn how to become stronger & more capable as a{" "}
                <EmphasizedText>Truly Human LEADER</EmphasizedText>. Resources
                listed at the bottom of the page will help you strengthen your
                influence.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>
      </QuadrantsContainer>
    );
  } else if (renderQuadrant3) {
    quadrant = (
      <QuadrantsContainer>
        <TitleMain>
          <UnderlinedText>Your Predominant Leadership Style</UnderlinedText>
        </TitleMain>

        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant3Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant III: Charismatic General</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              If you’re a Quadrant III Leader, you tend to inspire{" "}
              <EmphasizedText>collaborative commitment</EmphasizedText>;
              however, you’re clearly ‘in charge’.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>
                When leading troops into ‘battle’ where casualties are a true
                possibility
              </RegularListItem>
              <RegularListItem>
                When ‘bigger than us’ synchronized commitment is required
              </RegularListItem>
              <RegularListItem>
                When needed to rapidly develop an “us-vs-them” synergy
              </RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                The ability to charismatically{" "}
                <EmphasizedText>inspire</EmphasizedText> requires acute ability
                & consistent energy that can be rapidly diminished by external
                events & narratives or perceived ‘cracks in your armor’. And
                since none are perfect, there are always ‘cracks’ awaiting
                exploitation by those who would choose to oppose you.
              </RegularListItem>
              <RegularListItem>
                Being charismatic is often a natural characteristic. If you have
                such abilities, it can be easy to convince yourself you know
                best and then use your ‘power’ to entice others to follow.
              </RegularListItem>

              <RegularListItem>
                The challenge is- you do NOT always know best. You should
                consistently remind yourself of this PPI Key Insight:
                <Subtitle>No one of us is as smart as all of us </Subtitle>
              </RegularListItem>
              <RegularListItem>
                Consciously seek the input of others, including actively
                appointing a ‘devil’s advocate’ to overtly question your
                decisions & motivations.
              </RegularListItem>
              <RegularListItem>
                Actively promote use of the{" "}
                <EmphasizedText>ASK Questions</EmphasizedText> Tool.
              </RegularListItem>
              <RegularListItem>
                Take action to move more and more into Quadrant IV by countering
                any Team Member tendencies to ‘blindly’ follow. Resources listed
                at the bottom of the page will help you make the transition.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>

        <TitleMain>
          <UnderlinedText>Other Predominant Leadership Styles</UnderlinedText>
        </TitleMain>
        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant1Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant I: Conventional BOSS</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              When your predominant leadership characteristics lie in Quadrant
              I, your tendencies are to command & control your Team Members.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>
                During the initial stages of ‘righting the ship’ of significant
                dysfunction
              </RegularListItem>
              <RegularListItem>
                When acute conditions require swift decision and action
              </RegularListItem>
              <RegularListItem>
                When disciplinary action is needed
              </RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                Consistently{" "}
                <EmphasizedText>commanding & controlling</EmphasizedText> during
                ‘normal’ workflows & interactions tends to alienate you from
                your Team Members. This often leads to reduced communication,
                lower trust, and creates a work environment of compliance
              </RegularListItem>
              <RegularListItem>
                While necessary at times (as indicated above) Quadrant I
                Leadership is diametrically opposed to Quadrant IV-
                <EmphasizedText>Truly Human LEADERSHIP</EmphasizedText>.{" "}
                <EmphasizedText>Truly Human LEADERS</EmphasizedText> generate a
                work environment of Desire, which is the{" "}
                <UnderlinedText>only</UnderlinedText> way to achieve sustainable
                above-expectation choices, actions, & behaviors by your Team
                Members.{" "}
                <EmphasizedText>Connection & Collaboration</EmphasizedText>, the
                characteristics of Quadrant IV LEADERSHIP, produce best outcomes
                & maximum results. As such, they will be most highly valued into
                the future.
              </RegularListItem>
              <RegularListItem>
                If you are predominantly a Quadrant I Leader, you should take
                action to move more and more into Quadrant IV. Resources listed
                at the bottom of the page will help you make the transition.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>

        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant2Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant II: Committee Director</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              When your predominant leadership characteristics lie in Quadrant
              II, your tendencies are to bring people together & control the
              outcomes.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>
                During the outset of brainstorming and/or plan development to
                articulate the initial vision
              </RegularListItem>
              <RegularListItem>
                To bring things ‘back on track’ during derailed meetings or
                brainstorming
              </RegularListItem>
              <RegularListItem>
                To stimulate discussion and/or engagement when stalemates occur
              </RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                Consistently <EmphasizedText>controlling</EmphasizedText>{" "}
                outcomes when Team Members are brought together tends to create
                apathy and even animosity toward group brainstorming or
                problem-solving. This can shut down active questioning and input
                during group sessions and individual interactions.
              </RegularListItem>
              <RegularListItem>
                While appropriate at times (as indicated above) be mindful of
                PPI Precepts <StrongText>3</StrongText> and{" "}
                <StrongText>4</StrongText>:
              </RegularListItem>
              <RegularList>
                <RegularListItem>
                  <StrongText>#3</StrongText>-
                  <EmphasizedText>
                    We come to work wanting to do a good job
                  </EmphasizedText>
                </RegularListItem>
                <RegularListItem>
                  <StrongText>#4</StrongText>-
                  <EmphasizedText>
                    Those who do the work have the answers
                  </EmphasizedText>
                </RegularListItem>
              </RegularList>
              <RegularListItem>
                If you have been predominantly in Quadrant II…
                <RegularList>
                  <RegularListItem>
                    Reveal to your Team Members what you’ve recognized about
                    your past behaviors (yes- this takes guts).
                  </RegularListItem>
                  <RegularListItem>
                    Let them know you recognize there’s a better way forward and
                    share this PPI Key Insight:
                  </RegularListItem>
                </RegularList>
                <Subtitle>No one of us is as smart as all of us </Subtitle>
              </RegularListItem>
              <RegularListItem>
                Mindfully release control and allow Team Member inputs to
                prevail (as appropriate).
              </RegularListItem>
              <RegularListItem>
                Take action to move more and more into Quadrant IV by releasing
                into and actively promoting{" "}
                <EmphasizedText>collaboration</EmphasizedText> in brainstorming
                and decision-making wherever possible. Resources listed at the
                bottom of the page will help you make the transition.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>

        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant4Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant IV: Truly Human LEADER</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              The more your predominant characteristics lie in Quadrant IV, the
              more you will tend to move your work environment toward Desire and
              <EmphasizedText></EmphasizedText> the more rapidly you can grow a
              Culture of One Team with One Goal having One Conversation.
            </RegularText>
            <RegularText>
              When you do so, you will be a force for generating{" "}
              <EmphasizedText>sustainable</EmphasizedText> and
              <EmphasizedText>continually-improving</EmphasizedText> WIN-WIN-WIN
              Outcomes & Results.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>MOST OF THE TIME</RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                Recognize there are times when biasing toward one of the other
                quadrants is appropriate (as explained for each of the other
                Quadrants). Such flexibility is referred to as Situational
                Leadership. <StrongText>Note:</StrongText> A classic work on
                Situational Leadership is The One Minute Manager. A link to the
                book is provided in the Resources Section below.
              </RegularListItem>
              <RegularListItem>
                A potential downfall of{" "}
                <EmphasizedText>Truly Human Leaders</EmphasizedText> is
                successes achieved, which can lead to complacency.
              </RegularListItem>

              <RegularListItem>
                Be aware that Natural Principles are at work in all things,
                including Human & Team Performance. Mindfulness and applied
                energy are required on an ongoing basis to maintain upward
                trajectory and momentum.
              </RegularListItem>
              <RegularListItem>
                Actively learn how to become stronger & more capable as a{" "}
                <EmphasizedText>Truly Human LEADER</EmphasizedText>. Resources
                listed at the bottom of the page will help you strengthen your
                influence.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>
      </QuadrantsContainer>
    );
  } else if (renderQuadrant4) {
    quadrant = (
      <QuadrantsContainer>
        <TitleMain>
          <UnderlinedText>Your Predominant Leadership Style</UnderlinedText>
        </TitleMain>

        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant4Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant IV: Truly Human LEADER</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              The more your predominant characteristics lie in Quadrant IV, the
              more you will tend to move your work environment toward Desire and
              <EmphasizedText></EmphasizedText> the more rapidly you can grow a
              Culture of One Team with One Goal having One Conversation.
            </RegularText>
            <RegularText>
              When you do so, you will be a force for generating{" "}
              <EmphasizedText>sustainable</EmphasizedText> and
              <EmphasizedText>continually-improving</EmphasizedText> WIN-WIN-WIN
              Outcomes & Results.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>MOST OF THE TIME</RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                Recognize there are times when biasing toward one of the other
                quadrants is appropriate (as explained for each of the other
                Quadrants). Such flexibility is referred to as Situational
                Leadership. <StrongText>Note:</StrongText> A classic work on
                Situational Leadership is The One Minute Manager. A link to the
                book is provided in the Resources Section below.
              </RegularListItem>
              <RegularListItem>
                A potential downfall of{" "}
                <EmphasizedText>Truly Human Leaders</EmphasizedText> is
                successes achieved, which can lead to complacency.
              </RegularListItem>

              <RegularListItem>
                Be aware that Natural Principles are at work in all things,
                including Human & Team Performance. Mindfulness and applied
                energy are required on an ongoing basis to maintain upward
                trajectory and momentum.
              </RegularListItem>
              <RegularListItem>
                Actively learn how to become stronger & more capable as a{" "}
                <EmphasizedText>Truly Human LEADER</EmphasizedText>. Resources
                listed at the bottom of the page will help you strengthen your
                influence.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>

        <TitleMain>
          <UnderlinedText>Other Predominant Leadership Styles</UnderlinedText>
        </TitleMain>
        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant1Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant I: Conventional BOSS</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              When your predominant leadership characteristics lie in Quadrant
              I, your tendencies are to command & control your Team Members.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>
                During the initial stages of ‘righting the ship’ of significant
                dysfunction
              </RegularListItem>
              <RegularListItem>
                When acute conditions require swift decision and action
              </RegularListItem>
              <RegularListItem>
                When disciplinary action is needed
              </RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                Consistently{" "}
                <EmphasizedText>commanding & controlling</EmphasizedText> during
                ‘normal’ workflows & interactions tends to alienate you from
                your Team Members. This often leads to reduced communication,
                lower trust, and creates a work environment of compliance
              </RegularListItem>
              <RegularListItem>
                While necessary at times (as indicated above) Quadrant I
                Leadership is diametrically opposed to Quadrant IV-
                <EmphasizedText>Truly Human LEADERSHIP</EmphasizedText>.{" "}
                <EmphasizedText>Truly Human LEADERS</EmphasizedText> generate a
                work environment of Desire, which is the{" "}
                <UnderlinedText>only</UnderlinedText> way to achieve sustainable
                above-expectation choices, actions, & behaviors by your Team
                Members.{" "}
                <EmphasizedText>Connection & Collaboration</EmphasizedText>, the
                characteristics of Quadrant IV LEADERSHIP, produce best outcomes
                & maximum results. As such, they will be most highly valued into
                the future.
              </RegularListItem>
              <RegularListItem>
                If you are predominantly a Quadrant I Leader, you should take
                action to move more and more into Quadrant IV. Resources listed
                at the bottom of the page will help you make the transition.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>

        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant2Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant II: Committee Director</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              When your predominant leadership characteristics lie in Quadrant
              II, your tendencies are to bring people together & control the
              outcomes.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>
                During the outset of brainstorming and/or plan development to
                articulate the initial vision
              </RegularListItem>
              <RegularListItem>
                To bring things ‘back on track’ during derailed meetings or
                brainstorming
              </RegularListItem>
              <RegularListItem>
                To stimulate discussion and/or engagement when stalemates occur
              </RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                Consistently <EmphasizedText>controlling</EmphasizedText>{" "}
                outcomes when Team Members are brought together tends to create
                apathy and even animosity toward group brainstorming or
                problem-solving. This can shut down active questioning and input
                during group sessions and individual interactions.
              </RegularListItem>
              <RegularListItem>
                While appropriate at times (as indicated above) be mindful of
                PPI Precepts <StrongText>3</StrongText> and{" "}
                <StrongText>4</StrongText>:
              </RegularListItem>
              <RegularList>
                <RegularListItem>
                  <StrongText>#3</StrongText>-
                  <EmphasizedText>
                    We come to work wanting to do a good job
                  </EmphasizedText>
                </RegularListItem>
                <RegularListItem>
                  <StrongText>#4</StrongText>-
                  <EmphasizedText>
                    Those who do the work have the answers
                  </EmphasizedText>
                </RegularListItem>
              </RegularList>
              <RegularListItem>
                If you have been predominantly in Quadrant II…
                <RegularList>
                  <RegularListItem>
                    Reveal to your Team Members what you’ve recognized about
                    your past behaviors (yes- this takes guts).
                  </RegularListItem>
                  <RegularListItem>
                    Let them know you recognize there’s a better way forward and
                    share this PPI Key Insight:
                  </RegularListItem>
                </RegularList>
                <Subtitle>No one of us is as smart as all of us </Subtitle>
              </RegularListItem>
              <RegularListItem>
                Mindfully release control and allow Team Member inputs to
                prevail (as appropriate).
              </RegularListItem>
              <RegularListItem>
                Take action to move more and more into Quadrant IV by releasing
                into and actively promoting{" "}
                <EmphasizedText>collaboration</EmphasizedText> in brainstorming
                and decision-making wherever possible. Resources listed at the
                bottom of the page will help you make the transition.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>

        <QuadrantContainer>
          <TopContainer>
            <GraphicContainer>
              <QuadrantImage src={quadrant3Image}></QuadrantImage>
            </GraphicContainer>
            <TitleContainer>
              <Title>Quadrant III: Charismatic General</Title>
            </TitleContainer>
          </TopContainer>
          <BottomContainer>
            <RegularText>
              If you’re a Quadrant III Leader, you tend to inspire{" "}
              <EmphasizedText>collaborative commitment</EmphasizedText>;
              however, you’re clearly ‘in charge’.
            </RegularText>
            <Subtitle>This leadership style can be appropriate:</Subtitle>
            <RegularList>
              <RegularListItem>
                When leading troops into ‘battle’ where casualties are a true
                possibility
              </RegularListItem>
              <RegularListItem>
                When ‘bigger than us’ synchronized commitment is required
              </RegularListItem>
              <RegularListItem>
                When needed to rapidly develop an “us-vs-them” synergy
              </RegularListItem>
            </RegularList>
            <Subtitle>Cautions & Considerations for the Future:</Subtitle>
            <RegularList>
              <RegularListItem>
                The ability to charismatically{" "}
                <EmphasizedText>inspire</EmphasizedText> requires acute ability
                & consistent energy that can be rapidly diminished by external
                events & narratives or perceived ‘cracks in your armor’. And
                since none are perfect, there are always ‘cracks’ awaiting
                exploitation by those who would choose to oppose you.
              </RegularListItem>
              <RegularListItem>
                Being charismatic is often a natural characteristic. If you have
                such abilities, it can be easy to convince yourself you know
                best and then use your ‘power’ to entice others to follow.
              </RegularListItem>

              <RegularListItem>
                The challenge is- you do NOT always know best. You should
                consistently remind yourself of this PPI Key Insight:
                <Subtitle>No one of us is as smart as all of us </Subtitle>
              </RegularListItem>
              <RegularListItem>
                Consciously seek the input of others, including actively
                appointing a ‘devil’s advocate’ to overtly question your
                decisions & motivations.
              </RegularListItem>
              <RegularListItem>
                Actively promote use of the{" "}
                <EmphasizedText>ASK Questions</EmphasizedText> Tool.
              </RegularListItem>
              <RegularListItem>
                Take action to move more and more into Quadrant IV by countering
                any Team Member tendencies to ‘blindly’ follow. Resources listed
                at the bottom of the page will help you make the transition.
              </RegularListItem>
            </RegularList>
          </BottomContainer>
        </QuadrantContainer>
      </QuadrantsContainer>
    );
  }

  return (
    <div>
      {showGraph ? (
        <ResultContainer>
          <LogoContainer>
            <LogoImage src={logo}></LogoImage>
          </LogoContainer>
          <StepContainer>
            <Step>
              <StepTitle>
                <StepTitleText>STEP 2</StepTitleText>
              </StepTitle>
              <StepTextContainer>
                <StepText>
                  Consider your current leadership traits on the graph below.
                </StepText>
                <StepText>
                  The shape in the middle represents your current general
                  tendencies toward Command, Control, Connection, and
                  Collaboration.
                </StepText>
                <StepText>
                  In which current quadrant do most of your tendencies lie?
                </StepText>
              </StepTextContainer>
            </Step>
          </StepContainer>
          <ChartContainer>
            <ResponsiveRadar
              data={data}
              keys={["Total"]}
              indexBy="subject"
              maxValue={25}
              valueFormat=">-.2f"
              
              borderColor={{ from: "color", modifiers: [] }}
              gridLevels={0}
              gridShape="linear"
              gridLabelOffset={25}
              dotColor={{ theme: "background" }}
              dotBorderWidth={2}
              //colors={{ scheme: "nivo" }}
              colors={[color, "#222222"]}
              fillOpacity={0.9}
              motionConfig="wobbly"
              theme={theme}
              legends={[
                {
                  anchor: "top-left",
                  direction: "column",
                  translateX: -50,
                  translateY: -40,
                  itemWidth: 80,
                  itemHeight: 20,
                  itemTextColor: "#fff",
                  symbolSize: 12,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#fff",
                      },
                    },
                  ],
                },
              ]}
            />
          </ChartContainer>
          <StepContainer>
            <Step>
              <StepTitle>
                <StepTitleText>STEP 3</StepTitleText>
              </StepTitle>
              <StepTextContainer>
                <StepText>
                  Now that you’ve identified your predominant/natural Quadrant,
                  consider the following as you move forward into your future as
                  a LEADER{" "}
                </StepText>
                <StepText>
                  <StrongText> Note 1:</StrongText>
                  Your statement responses were likely based on your current
                  frame of mind (mindset & perception). This should represent
                  your natural tendencies during normal levels of stress &
                  activity. During acute levels of stress and/or shifting
                  conditions you may shift Quadrants. And this can be a GOOD
                  thing. For example, if you’re the Leader in a nuclear power
                  plant control room during an emergency, Command & Control are
                  necessary & appropriate, even though this might not be your
                  natural style.
                </StepText>
                <StepText>
                  <StrongText> Note 2:</StrongText> Into the future, you will
                  want to move your natural leadership style more and more
                  toward being a Truly Human Leader (into Quadrant IV- Connect
                  &Collaborate). Societal, generational, and technical trends
                  (think AI) are converging in a way that’s creating a much
                  greater need for (and responsiveness to) Quadrant IV
                  LEADERSHIP.
                </StepText>
                <StepText>
                  <StrongText> Note 3:</StrongText> Characteristics and
                  suggestions for each LEADERSHIP Quadrant are provided below.
                  Links to additional insights & information, including the What
                  Type Leader Are You? Training Video, are provided below.
                </StepText>
              </StepTextContainer>
            </Step>
          </StepContainer>
          {quadrant}
        </ResultContainer>
      ) : (
        <QuizContainer>
          <LogoContainer>
            <LogoImage src={logo}></LogoImage>
          </LogoContainer>
          <DescriptionContainer>
            <DescriptionText>
              Discover YOUR current predominant Leadership traits & how they'll
              IMPACT YOUR FUTURE!
            </DescriptionText>
          </DescriptionContainer>

          <StepContainer>
            <Step>
              <StepTitle>
                <StepTitleText>STEP 1</StepTitleText>
              </StepTitle>
              <StepTextContainer>
                <StepText>
                  Rate each of the following statements from [Strongly Agree] to
                  [Strongly Disagree]
                </StepText>
              </StepTextContainer>
            </Step>
          </StepContainer>
          <BuzzFeedQuiz
            title={null}
            description={null}
            byline={false}
            bylineAuthor={"Your name"}
            bylineAuthorLink={"www.yourlink.com"}
            bylineAuthorLinkOpenInNewTab={true}
            bylineAuthorTagline={"Your author tagline goes here"}
            bylineAvatarImageSrc={ProfilePhoto}
            autoScroll={true}
            onRestart={() =>
              alert("This alert was triggered by the onRestart prop!")
            }
            onResult={(r) => {
              console.log(r);
              console.log("Ready");
            }}
            onAnswerSelection={(questionIndex, answerIndex, resultID) => {
              var newAnswers = answers;

              var answer = {
                question: questionIndex,
                answerIndex: answerIndex,
                resultID: resultID,
              };

              newAnswers.splice(questionIndex, 1, answer);

              setAnswers(newAnswers);

              if (questionIndex === 15) {
                renderData();
              }
            }}
            facebookShareButton={true}
            facebookShareLink={"www.yourlink.com"}
            twitterShareButton={true}
            twitterShareLink={"www.yourlink.com"}
            copyShareButton={true}
            copyShareLink={"This text was copied using the copyShareLink prop."}
            questions={[
              {
                question:
                  "Most Team Members feel insecure about their work and need direction",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Team Members need to be supervised closely or they're not likely to do their work",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Effective Leaders give orders and clarify procedures",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Team Member motivation comes primarily through direct external reward and/or punishment",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Effective Leaders are specific, detailed, and direct",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "The Leader is chief judge of Team Member achievements",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "It is imperative for Team Members to know who is in charge",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Leadership requires constant monitoring and feedback",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "In complex situations, Leaders should give Team Members as much latitude as possible to 'fix' things on their own",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "As a rule, Leaders, Supervisors, and Individual Contributors should work together collectively to make plans and evaluate work",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Team Members should be actively engaged in the decision-making process",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "The Leader's role is to bring appropriate people/teams/departments together to generate ideas and solve problems",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Team Members are basically competent and want to do a good job",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "It is much better to share vision and guidance across teams and departments (rather than caring about who gets the credit)",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Highest levels of Reliability, Efficiency, Productivity, and Safety come through spontaneous cooperation of Team Members",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Diverse input maximizes the potential for and magnitude of success",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                style: { color: "red" },
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
            ]}
            results={[
              {
                title: "Your first result title goes here",
                description: "Your first result description goes here",
                resultImageSrc: FirstResultImage,
                imageAttribution: "Your photo attribution text goes here",
                resultID: 0,
              },
              {
                title: "Your second result title goes here",
                description: "Your second result description goes here",
                resultImageSrc: SecondResultImage,
                resultID: 1,
              },
              {
                title: "Your third result title goes here",
                description: "Result images and attributions are optional!",
                resultID: 2,
              },
              {
                title: "Your third result title goes here",
                description: "Result images and attributions are optional!",
                resultID: 3,
              },
              {
                title: "Your third result title goes here",
                description: "Result images and attributions are optional!",
                resultID: 4,
              },
              {
                title: "Your third result title goes here",
                description: "Result images and attributions are optional!",
                resultID: 5,
              },
            ]}
          />
        </QuizContainer>
      )}

      {/* <Radar
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 25,
                  stepSize: 5,
                },
              },
            ],
          },
        }}
        data={{
          labels: ["COLLABORATE", "CONNECT", "CONTROL", "COMMAND"],
          datasets: [
            {
              label: "# of Votes",
              data: [
                collaborateTotal,
                connectTotal,
                controlTotal,
                commandTotal,
              ],
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 2,
            },
          ],
        }}
      /> */}
    </div>
  );
}

export default Questions;

const QuizContainer = styled.div``;

const ResultContainer = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StepContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px;

  @media (max-width: 550px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5px;
  }

`;

const Step = styled.div`
  border: 2px solid #38b216;
  width: 100%;
  display: flex;
  flex-direction: row;
margin:5px;

`;

const StepTitle = styled.div`
  width: 25%;
  background-color: #25364c;
  display: flex;
  align-items: center;
  justify-content: center;

`;

const StepTitleText = styled.h1`
  text-align: center;
  color: white;

  @media (max-width: 550px) {
    font-size:12px;
    font-weight:bold;
  }
  
`;

const StepTextContainer = styled.div`
  padding: 5px;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StepText = styled.p`
  width: 100%;
  font-size: 18px;
  padding: 5px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
`;

const LogoImage = styled.img`
  width: 35%;
  height: 35%;
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DescriptionText = styled.h1`
  width: 60%;
  font-size: 25px;
  text-align: center;
  font-weight: 400;
`;

const QuadrantsContainer = styled.div`
  padding: 5px;
  background-color: #25364c;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QuadrantContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #2192bd;
  border-radius: 25px;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  width: 80%;
`;

const GraphicContainer = styled.div`
  width: 50%;
`;

const QuadrantImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const TitleContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExplanationContainer = styled.div`
  width: 70%;
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  overflow: hidden;
`;

const BottomContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;
const BottomContainerResult = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 80%;
  align-self: center;
`;

const TitleMain = styled.h1`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
`;

const Title = styled.h1`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: black;
`;

const TitleStyle = styled.h1`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
`;

const Subtitle = styled.h1`
  font-size: 20px;
  color: #2192bd;
`;

const RegularText = styled.p`
  font-size: 18px;
`;

const ChartContainer = styled.div`
  height: 275px;
  width: 500px;
  display: flex;
  align-items: center;
  margin: 20px;
  background-color: #25364c;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${gridImage});
`;

const GridImage = styled.img``;

const UnderlinedText = styled.u``;
const RegularList = styled.ul``;
const RegularListItem = styled.li`
  margin-top: 10px;
  list-style: none;

  '&:before': {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: red; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  },
`;
const EmphasizedText = styled.em``;
const StrongText = styled.strong``;

const CustomQuestionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const QuestionContainer = styled.div`
  width: 50%;
  height: 90px;
  display: flex;
  background-color: #25364c;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  border: 2px solid #38b216;
  margin: 5px;
`;

const QuestionTitle = styled.h1`
  width: 100%;
  color: white;
  font-size: 25px;
  text-align: center;
  margin-bottom: 2px;
`;

const QuestionOption = styled.input`
  margin: 5px;
`;

const QuestionSelection = styled.select`
  height: 30px;
  font-size: 20px;
  text-align: center;
`;

const SelectionOption = styled.option``;

const SubtitleLink = styled.a`
  color: white;
`;

const TitleQuadrant = styled.p`
  width: 80%;
  color: #2192bd;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
`;

const SubtitleLinkQuadrant = styled.a`
  padding: 5px;
  font-size: 18px;
  font-weight: 500;
  margin: 10px;
`;

const ResultFormQuestionContainer = styled.div`
  width: 30%;
  background-color: #25364c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 5px;
`;

const ResultFormQuestion = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const ShowMyResultsButton = styled.button`
  margin: 20px;
  width: 70%;
  height: 50px;
  background-color: #2192bd;
  font-size: 18px;
  color: white;
  border-radius: 10px;
`;

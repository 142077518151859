import "./App.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import ABCHome from "../src/pages/ABCHome";
import Dashboard from "./Dashboard";
import DashboardMarketing from "./DashboardMarketing";
import Header from "./components/Header";
import Login from "./Login";
import LoginMarketing from "./LoginMarketing";
import PPIHome from "../src/pages/PPIHome";
import React from "react";
import Register from "./Register";
import Reset from "./Reset";

function App() {
  return (
    <div className="app">
      <Router>
        <Header></Header>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/home" element={<PPIHome />} />
          <Route exact path="/abc" element={<ABCHome />} />
          <Route exact path="/wtlay" element={<Dashboard />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/wtlay/m" element={<LoginMarketing />} />
          <Route
            exact
            path="/wtlay/dashboard2"
            element={<DashboardMarketing />}
          />
        </Routes>
      </Router>
    </div>
  );
}
export default App;

import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { auth, db, logOut } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

import appLogo from "../images/WTLAY-title-graphic.png";
import ppi from "../images/PPI logo_2022_white_background.webp";
import styled from "styled-components";
import { useAuthState } from "react-firebase-hooks/auth";

function Header() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  // const fetchUserName = async () => {
  //   try {
  //     const q = query(collection(db, "users"), where("uid", "==", user?.uid));
  //     const doc = await getDocs(q);
  //     const data = doc.docs[0].data();
  //     setName(data.name);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  useEffect(() => {
    if (loading) return;

    // try {
    //   const q = query(collection(db, "users"), where("uid", "==", user?.uid));
    //   const doc = getDocs(q);
    //   const data = doc.docs[0].data();
    //   setName(data.name);
    // } catch (err) {
    //   console.error(err);
    // }
  }, [user, loading]);

  return (
    <MainContainer>
      {user ? (
        <PPILOGO
          src={ppi}
          onClick={() => {
            navigate("../home", { replace: true });
            console.log("GO Home");
          }}
        ></PPILOGO>
      ) : (
        <PPILOGO
          src={ppi}
          onClick={() => {
            navigate("../", { replace: true });
            navigate(0);
            console.log("GO Login");
          }}
        ></PPILOGO>
      )}

      <ProfileContainer className="dashboard__container">
        <SimpleText>{name}</SimpleText>
        <SimpleText>{user?.email}</SimpleText>

        {user ? (
          <LogOutButton
            onClick={() => {
              logOut();
              navigate("../", { replace: true });
              navigate(0);
              console.log("Log Out");
            }}
          >
            Logout
          </LogOutButton>
        ) : null}
      </ProfileContainer>
    </MainContainer>
  );
}

export default Header;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  border-bottom-width: 1;
  border-bottom-color: #38b216;
  border-bottom-style: solid;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }

`;

const Title = styled.h1``;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SimpleText = styled.p`
  line-height: 1.2px;
`;

const LogOutButton = styled.button`
  margin-top: 10px;
  width: 100px;
  height: 30px;
  font-size: 14px;
  border-radius: 20px;
  border: 1px solid white;
  background-color: #25364c;
  color: white;
`;

const PPILOGO = styled.img`
  width: 200px;
`;

const APPLOGO = styled.img`
  width: 200px;
`;

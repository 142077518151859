import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAxm1f3gWxS1Ee4z5GXtpkXCCoANTlWl-Y",
  authDomain: "ppi-main.firebaseapp.com",
  projectId: "ppi-main",
  storageBucket: "ppi-main.appspot.com",
  messagingSenderId: "567325076039",
  appId: "1:567325076039:web:cbcc3e208e368df8e49f62",
  measurementId: "G-Y9QKYK1E7V",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;

    await setDoc(
      doc(db, "users", user.uid),
      {
        uid: user.uid,
        name,
        authProvider: "local",
        email,
        abc: {
          name: null,
          grade: null,
          score: null,
          color: null,
          q1: 0,
          q2: 0,
          q3: 0,
          q4: 0,
          q5: 0,
        },
        abcTeam: [],
      },
      { merge: true }
    );

    // await addDoc(collection(db, "users"), {
    //   uid: user.uid,
    //   name,
    //   authProvider: "local",
    //   email,
    // });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPasswordMarketing = async (
  name,
  email,
  password,
  phone,
  dominantQuadrant,
  occupation,
  companySize
) => {
  try {
    console.log("Name:" + name);
    console.log("Email:" + email);
    console.log("Password:" + password);
    console.log("Phone:" + phone);
    console.log("Quadrant:" + dominantQuadrant);
    console.log("Occupation:" + occupation);
    console.log("CompanySize:" + companySize);
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;

    await setDoc(
      doc(db, "users", user.uid),
      {
        uid: user.uid,
        name,
        authProvider: "local",
        email,
        wtlay: { quadrant: dominantQuadrant },
        wtlayResponses: [],
        phone: phone,
        role: occupation,
        sizeofOrg: companySize,
        abc: {
          name: null,
          grade: null,
          score: null,
          color: null,
          q1: 0,
          q2: 0,
          q3: 0,
          q4: 0,
          q5: 0,
        },
        abcTeam: [],
      },
      { merge: true }
    ).then(() => {
      console.log("After Action");
    });

    if (dominantQuadrant === 0) {
      console.log("Dominant Quadrant");
      console.log(dominantQuadrant);
      window.open("https://ppiglobalaccess.com/wtlay-q0", "_blank");
    } else if (dominantQuadrant === 1) {
      console.log("Dominant Quadrant");
      console.log(dominantQuadrant);
      window.open("https://ppiglobalaccess.com/wtlay-q1", "_blank");
    } else if (dominantQuadrant === 2) {
      console.log("Dominant Quadrant");
      console.log(dominantQuadrant);
      window.open("https://ppiglobalaccess.com/wtlay-q2", "_blank");
    } else if (dominantQuadrant === 3) {
      console.log("Dominant Quadrant");
      console.log(dominantQuadrant);
      window.open("https://ppiglobalaccess.com/wtlay-q3", "_blank");
    } else if (dominantQuadrant === 4) {
      console.log("Dominant Quadrant");
      console.log(dominantQuadrant);
      window.open("https://ppiglobalaccess.com/wtlay-q4", "_blank");
    }
    // await addDoc(collection(db, "users"), {
    //   uid: user.uid,
    //   name,
    //   authProvider: "local",
    //   email,
    // });
  } catch (err) {
    console.error(err);

    if (err.message === "Firebase: Error (auth/email-already-in-use).") {
      if (dominantQuadrant === 1) {
        console.log("Dominant Quadrant");
        console.log(dominantQuadrant);
        window.open("https://ppiglobalaccess.com/wtlay-q1", "_blank");
      } else if (dominantQuadrant === 2) {
        console.log("Dominant Quadrant");
        console.log(dominantQuadrant);
        window.open("https://ppiglobalaccess.com/wtlay-q2", "_blank");
      } else if (dominantQuadrant === 3) {
        console.log("Dominant Quadrant");
        console.log(dominantQuadrant);
        window.open("https://ppiglobalaccess.com/wtlay-q3", "_blank");
      } else if (dominantQuadrant === 4) {
        console.log("Dominant Quadrant");
        console.log(dominantQuadrant);
        window.open("https://ppiglobalaccess.com/wtlay-q4", "_blank");
      }
    } else {
      alert(err.message);
    }
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const signInAnonymous = async () => {
  try {
    await signInAnonymously(auth);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logOut = () => {
  console.log("Loggin Out");

  signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
      console.log(error);
    });
};

const abcAddTeamMember = async () => {
  console.log(auth);
  // await setDoc(
  //   doc(db, "users", user.uid),
  //   {
  //     abc: {},
  //   },
  //   { merge: true }
  // ).then(() => {
  //   console.log("After Action");
  // });
};

export {
  auth,
  db,
  signInWithGoogle,
  signInAnonymous,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  registerWithEmailAndPasswordMarketing,
  sendPasswordReset,
  logOut,
  abcAddTeamMember,
};

import "./Reset.css";

import React, { useEffect, useState } from "react";
import { auth, sendPasswordReset } from "./firebase";

import { Link } from "react-router-dom";
import ppi from "./images/PPI logo_2022_white_background.webp";
import styled from "styled-components";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

function Reset() {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) return;
    if (user) navigate("../");
  }, [user, loading]);
  return (
    <MainContainer className="reset">
      <LoginContainer className="reset__container">
        <LogoContainer>
          <PPILOGO src={ppi}></PPILOGO>
        </LogoContainer>

        <ResetTitle>RESET PASSWORD</ResetTitle>
        <input
          type="text"
          className="reset__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <LoginButton
          className="reset__btn"
          onClick={() => sendPasswordReset(email)}
        >
          Send password reset email
        </LoginButton>
        <LoginButton
          onClick={() => {
            navigate("../");
          }}
        >
          Go Back
        </LoginButton>
      </LoginContainer>
    </MainContainer>
  );
}
export default Reset;

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #25364c;
`;

const ResetTitle = styled.h1``;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #25364c;
  width: 50%;

  padding: 30px;
  border-radius: 20px;
  background-color: white;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const PPILOGO = styled.img`
  width: 80%;
  margin-right: 10px;
`;

const APPLOGO = styled.img`
  width: 30%;
  margin: 5px;
`;

const LoginButton = styled.button`
  border-radius: 10px;
  color: white;
  background-color: #25364c;
  height: 40px;
  font-size: 18px;
`;

import "./Dashboard.css";

import React, { useEffect, useState } from "react";
import { auth, db, logout } from "./firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

import LogRocket from "logrocket";
import Questions from "../src/components/Questions";
import appLogo from "./images/WTLAY-title-graphic.png";
import ppi from "./images/PPI logo_2022_white_background.webp";
import styled from "styled-components";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  // LogRocket.init("m913fv/wtlay");
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };
  useEffect(() => {
    if (loading) return;

    fetchUserName();
  }, [user, loading]);

  return (
    <Container>
      <Questions></Questions>
      <FooterContainer>
        <SubtitleTitle>Resources for LEADING Into the Future…</SubtitleTitle>
        <SubtitleSubTitle>Videos</SubtitleSubTitle>
        <RegularListFooter>
          <SubtitleLink
            href={"https://ppiglobalaccess.com/wtlay-training-video"}
            target="_blank"
          >
            <RegularListItemFooter>
              What Type Leader Are You?
            </RegularListItemFooter>
          </SubtitleLink>
          <SubtitleLink
            href={
              "https://ppiglobalaccess.com/wtlay-video-compliance-vs-desire"
            }
            target="_blank"
          >
            <RegularListItemFooter>Compliance vs DESIRE</RegularListItemFooter>
          </SubtitleLink>
          <SubtitleLink
            href={"https://ppiglobalaccess.com/wtlay-precepts-3-4"}
            target="_blank"
          >
            <RegularListItemFooter>PPI Precepts 3 & 4</RegularListItemFooter>
          </SubtitleLink>
        </RegularListFooter>
        <SubtitleSubTitle>Courses & Learning Opportunities</SubtitleSubTitle>
        <RegularListFooter>
          <SubtitleLink
            href={"https://ppiglobalaccess.com/hpi-leadership"}
            target="_blank"
          >
            <RegularListItemFooter>
              Principle-Based Leadership™
            </RegularListItemFooter>
          </SubtitleLink>
          <SubtitleLink
            href={"https://ppiglobalaccess.com/lff-info-request"}
            target="_blank"
          >
            <RegularListItemFooter>
              FLS Development-Leading From the Front™
            </RegularListItemFooter>
          </SubtitleLink>
          <SubtitleLink
            href={"https://ppiglobalaccess.com/hp-basic-training"}
            target="_blank"
          >
            <RegularListItemFooter>
              Human Performance BASIC Training™
            </RegularListItemFooter>
          </SubtitleLink>
          <SubtitleLink
            href={"https://ppiglobalaccess.com/pi-30-summit"}
            target="_blank"
          >
            <RegularListItemFooter>
              PPI Certification SUMMIT
            </RegularListItemFooter>
          </SubtitleLink>
        </RegularListFooter>
        <SubtitleSubTitle>Books</SubtitleSubTitle>
        <RegularListFooter>
          <SubtitleLink
            href={"https://www.6hoursafetyculture.com/"}
            target="_blank"
          >
            <RegularListItemFooter>
              <EmphasizedText>6-Hour Safety Culture</EmphasizedText> by Tim
              Autrey
            </RegularListItemFooter>
          </SubtitleLink>
          <SubtitleLink href={"https://amzn.to/3CDQxIO"} target="_blank">
            <RegularListItemFooter>
              <EmphasizedText>The One Minute Manager</EmphasizedText> by Ken
              Blanchard & Spencer Johnson
            </RegularListItemFooter>
          </SubtitleLink>

          <SubtitleLink
            href={"https://ppiglobalaccess.com/pi-book-club52036277"}
            target="_blank"
          >
            <RegularListItemFooter>
              Tim's Recommended Reading
            </RegularListItemFooter>
          </SubtitleLink>
        </RegularListFooter>
        <SubtitleSubTitle>Additional Apps & Tools</SubtitleSubTitle>
        <RegularListFooter>
          <SubtitleLink
            href={"https://ppiglobalaccess.com/ee-tools-english"}
            target="_blank"
          >
            <RegularListItemFooter>
              Error Elimination Tools™
            </RegularListItemFooter>
          </SubtitleLink>
          <SubtitleLink
            href={"https://ppiglobalaccess.com/gen-mindset"}
            target="_blank"
          >
            <RegularListItemFooter>
              What’s Your Gen Mindset?
            </RegularListItemFooter>
          </SubtitleLink>
          <SubtitleLink
            href={"https://ppiglobalaccess.com/cost-of-error-calculator-offer"}
            target="_blank"
          >
            <RegularListItemFooter>
              Cost-of-Error Calculator
            </RegularListItemFooter>
          </SubtitleLink>
          <SubtitleLink
            href={"https://ppiglobalaccess.com/host-training"}
            target="_blank"
          >
            <RegularListItemFooter>Host-a-Course</RegularListItemFooter>
          </SubtitleLink>
          <SubtitleLink
            href={"https://ppiglobalaccess.com/community-resources"}
            target="_blank"
          >
            <RegularListItemFooter>
              PPI COMMUNITY Resources
            </RegularListItemFooter>
          </SubtitleLink>
        </RegularListFooter>
        <CopyrightContainer>
          <CopyrightText>
            © 2022 PPI GLobal, Ltd. All Rights Reserved
          </CopyrightText>
        </CopyrightContainer>
      </FooterContainer>
    </Container>
  );
}
export default Dashboard;

const Container = styled.div`
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  border-bottom-width: 1;
  border-bottom-color: #38b216;
  border-bottom-style: solid;
`;

const Title = styled.h1``;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
`;

const SimpleText = styled.p`
  line-height: 1.2px;
`;

const LogOutButton = styled.button`
  margin-top: 10px;
  width: 100px;
  height: 30px;
  font-size: 14px;
  border-radius: 20px;
  border: 1px solid white;
`;

const PPILOGO = styled.img`
  width: 200px;
`;

const APPLOGO = styled.img`
  width: 200px;
`;

const RegularListItemFooter = styled.li`
  margin: 15px;
`;
const FooterContainer = styled.div`
  margin: 20px;
  border-radius: 25px;
  padding: 30px;
  background-color: #25364c;
`;
const SubtitleTitle = styled.h1`
  color: white;
  text-align: center;
  width: 100%;
`;
const SubtitleSubTitle = styled.h1`
  color: white;
`;
const StrongText = styled.strong``;

const EmphasizedText = styled.em``;

const UnderlinedText = styled.u``;
const RegularList = styled.ul``;
const RegularListFooter = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const RegularListItem = styled.li`
  margin-top: 10px;
`;

const SubtitleFooter = styled.h1`
  color: white;
`;

const SubtitleText = styled.p`
  color: white;
`;

const SubtitleLink = styled.a`
  color: white;
`;

const CopyrightContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const CopyrightText = styled.p`
  color: white;
  text-align: center;
  width: 100%;
`;

import "./ABCHome.css";

import { Link, useNavigate } from "react-router-dom";
import {
  RadioButton,
  RadioGroup,
  ReversedRadioButton,
} from "react-radio-buttons";
import React, { useEffect, useState } from "react";
import { auth, db, logout } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import LogRocket from "logrocket";
import Modal from "react-modal";
import appLogo from "../images/WTLAY-title-graphic.png";
import ppi from "../images/PPI logo_2022_white_background.webp";
import styled from "styled-components";
import { useAuthState } from "react-firebase-hooks/auth";

Modal.setAppElement("div");
function ABCHome() {
  let subtitle;

  const [user, error] = useAuthState(auth);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);

  const [userName, setUserName] = useState(null);
  const [userGrade, setUserGrade] = useState(null);
  const [userScore, setUserScore] = useState(null);

  // Question 1 User
  const [Q101, setQ101] = useState(false);
  const [Q102, setQ102] = useState(false);
  const [Q103, setQ103] = useState(false);
  const [Q1Value, setQ1Value] = useState(null);

  // Question 2 User
  const [Q201, setQ201] = useState(false);
  const [Q202, setQ202] = useState(false);
  const [Q203, setQ203] = useState(false);

  const [Q2Value, setQ2Value] = useState(null);
  // Question 3 User
  const [Q301, setQ301] = useState(false);
  const [Q302, setQ302] = useState(false);
  const [Q303, setQ303] = useState(false);
  const [Q3Value, setQ3Value] = useState(null);
  // Question 4 User
  const [Q401, setQ401] = useState(false);
  const [Q402, setQ402] = useState(false);
  const [Q403, setQ403] = useState(false);
  const [Q4Value, setQ4Value] = useState(null);
  // Question 5 User
  const [Q501, setQ501] = useState(false);
  const [Q502, setQ502] = useState(false);
  const [Q503, setQ503] = useState(false);

  const [Q5Value, setQ5Value] = useState(null);

  const [teamMemberName, setTeamMemberName] = useState(null);
  const [teamMemberGrade, setTeamMemberGrade] = useState(null);
  const [teamMemberScore, setTeamMemberScore] = useState(null);

  // Question 1 Team
  const [TQ101, setTQ101] = useState(false);
  const [TQ102, setTQ102] = useState(false);
  const [TQ103, setTQ103] = useState(false);
  const [TQ1Value, setTQ1Value] = useState(null);
  // Question 2 Team
  const [TQ201, setTQ201] = useState(false);
  const [TQ202, setTQ202] = useState(false);
  const [TQ203, setTQ203] = useState(false);
  const [TQ2Value, setTQ2Value] = useState(null);
  // Question 3 Team
  const [TQ301, setTQ301] = useState(false);
  const [TQ302, setTQ302] = useState(false);
  const [TQ303, setTQ303] = useState(false);
  const [TQ3Value, setTQ3Value] = useState(null);
  // Question 4 Team
  const [TQ401, setTQ401] = useState(false);
  const [TQ402, setTQ402] = useState(false);
  const [TQ403, setTQ403] = useState(false);
  const [TQ4Value, setTQ4Value] = useState(null);
  // Question 5 Team
  const [TQ501, setTQ501] = useState(false);
  const [TQ502, setTQ502] = useState(false);
  const [TQ503, setTQ503] = useState(false);
  const [TQ5Value, setTQ5Value] = useState(null);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [yourLevel, setYourLevel] = useState([
    {
      grade: null,
      name: null,
      q1: null,
      q2: null,
      q3: null,
      q4: null,
      q5: null,
      score: null,
    },
  ]);

  const [teamLevels, setTeamLevels] = useState([
    {
      grade: null,
      name: null,
      q1: null,
      q2: null,
      q3: null,
      q4: null,
      q5: null,
      score: null,
    },
  ]);

  const [sumTotal, setSumTotal] = useState(0);

  const [numberTeam, setNumberTeam] = useState(0);

  const [teamScoreTotal, setTeamScoreTotal] = useState(0);

  const [teamGradeTotal, setTeamGradeTotal] = useState("");

  const [boxColor, setBoxColor] = useState("red");

  const [loading, setLoading] = useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function openModal2() {
    setIsOpen2(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeModal2() {
    setIsOpen2(false);
  }

  function handleQuestion(e) {
    //console.log(e.target.value);

    const str = e;
    const question = str.split("|")[0];

    const answer = str.split("|")[1];

    console.log(question);
    console.log(answer);

    if (question === "1") {
      if (answer === "1") {
        setQ101(true);
        setQ102(false);
        setQ103(false);

        setQ1Value(1);
      } else if (answer === "2") {
        setQ101(false);
        setQ102(true);
        setQ103(false);

        setQ1Value(2);
      } else if (answer === "3") {
        setQ101(false);
        setQ102(false);
        setQ103(true);

        setQ1Value(3);
      }
    } else if (question === "2") {
      if (answer === "1") {
        setQ201(true);
        setQ202(false);
        setQ203(false);

        setQ2Value(1);
      } else if (answer === "2") {
        setQ201(false);
        setQ202(true);
        setQ203(false);

        setQ2Value(2);
      } else if (answer === "3") {
        setQ201(false);
        setQ202(false);
        setQ203(true);

        setQ2Value(3);
      }
    } else if (question === "3") {
      if (answer === "1") {
        setQ301(true);
        setQ302(false);
        setQ303(false);

        setQ3Value(1);
      } else if (answer === "2") {
        setQ301(false);
        setQ302(true);
        setQ303(false);

        setQ3Value(2);
      } else if (answer === "3") {
        setQ301(false);
        setQ302(false);
        setQ303(true);

        setQ3Value(3);
      }
    } else if (question === "4") {
      if (answer === "1") {
        setQ401(true);
        setQ402(false);
        setQ403(false);

        setQ4Value(1);
      } else if (answer === "2") {
        setQ401(false);
        setQ402(true);
        setQ403(false);

        setQ4Value(2);
      } else if (answer === "3") {
        setQ401(false);
        setQ402(false);
        setQ403(true);

        setQ4Value(3);
      }
    } else if (question === "5") {
      if (answer === "1") {
        setQ501(true);
        setQ502(false);
        setQ503(false);

        setQ5Value(1);
      } else if (answer === "2") {
        setQ501(false);
        setQ502(true);
        setQ503(false);

        setQ5Value(2);
      } else if (answer === "3") {
        setQ501(false);
        setQ502(false);
        setQ503(true);

        setQ5Value(3);
      }
    }
  }

  function handleQuestionsTeam(e) {
    const str = e;

    const question = str.split("|")[0];

    const answer = str.split("|")[1];

    console.log(question);
    console.log(answer);

    if (question === "1") {
      if (answer === "1") {
        setTQ101(true);
        setTQ102(false);
        setTQ103(false);

        setTQ1Value(1);
        console.log(TQ1Value);
      } else if (answer === "2") {
        setTQ101(false);
        setTQ102(true);
        setTQ103(false);

        setTQ1Value(2);
        console.log(TQ1Value);
      } else if (answer === "3") {
        setTQ101(false);
        setTQ102(false);
        setTQ103(true);

        setTQ1Value(3);
        console.log(TQ1Value);
      }
    } else if (question === "2") {
      if (answer === "1") {
        setTQ201(true);
        setTQ202(false);
        setTQ203(false);

        setTQ2Value(1);
        console.log(TQ2Value);
      } else if (answer === "2") {
        setTQ201(false);
        setTQ202(true);
        setTQ203(false);

        setTQ2Value(2);
        console.log(TQ2Value);
      } else if (answer === "3") {
        setTQ201(false);
        setTQ202(false);
        setTQ203(true);

        setTQ2Value(3);
        console.log(TQ2Value);
      }
    } else if (question === "3") {
      if (answer === "1") {
        setTQ301(true);
        setTQ302(false);
        setTQ303(false);

        setTQ3Value(1);
        console.log(TQ3Value);
      } else if (answer === "2") {
        setTQ301(false);
        setTQ302(true);
        setTQ303(false);

        setTQ3Value(2);
        console.log(TQ3Value);
      } else if (answer === "3") {
        setTQ301(false);
        setTQ302(false);
        setTQ303(true);

        setTQ3Value(3);
        console.log(TQ3Value);
      }
    } else if (question === "4") {
      if (answer === "1") {
        setTQ401(true);
        setTQ402(false);
        setTQ403(false);

        setTQ4Value(1);
        console.log(TQ4Value);
      } else if (answer === "2") {
        setTQ401(false);
        setTQ402(true);
        setTQ403(false);

        setTQ4Value(2);
        console.log(TQ4Value);
      } else if (answer === "3") {
        setTQ401(false);
        setTQ402(false);
        setTQ403(true);

        setTQ4Value(3);
        console.log(TQ4Value);
      }
    } else if (question === "5") {
      if (answer === "1") {
        setTQ501(true);
        setTQ502(false);
        setTQ503(false);

        setTQ5Value(1);
        console.log(TQ5Value);
      } else if (answer === "2") {
        setTQ501(false);
        setTQ502(true);
        setTQ503(false);

        setTQ5Value(2);
        console.log(TQ5Value);
      } else if (answer === "3") {
        setTQ501(false);
        setTQ502(false);
        setTQ503(true);

        setTQ5Value(3);
        console.log(TQ5Value);
      }
    }
  }

  function updateTeamData() {
    var currentTeam = teamLevels;

    console.log("Add Team to Db");

    var data = {
      name: teamMemberName,
      grade: teamMemberGrade,
      score: teamMemberScore,
      q1: TQ1Value,
      q2: TQ2Value,
      q3: TQ3Value,
      q4: TQ4Value,
      q5: TQ5Value,
    };

    currentTeam.push(data);

    console.log(currentTeam);

    setDoc(
      doc(db, "users", user.uid),
      { abcTeam: currentTeam },
      { merge: true }
    ).then(() => {
      console.log("Running after");
      setTQ1Value(0);
      setTQ2Value(0);
      setTQ3Value(0);
      setTQ4Value(0);
      setTQ5Value(0);
      setTQ101(false);
      setTQ102(false);
      setTQ103(false);
      setTQ201(false);
      setTQ202(false);
      setTQ203(false);
      setTQ301(false);
      setTQ302(false);
      setTQ303(false);
      setTQ401(false);
      setTQ402(false);
      setTQ403(false);
      setTQ501(false);
      setTQ502(false);
      setTQ503(false);
      setTeamMemberGrade(null);
      setTeamMemberScore(null);
      setTeamMemberName(null);
    });
    closeModal2();
  }

  function updateUserData() {
    var currentUser = yourLevel;

    console.log("Update User Db");

    var data = {
      name: userName,
      grade: userGrade,
      score: userScore,
      q1: Q1Value,
      q2: Q2Value,
      q3: Q3Value,
      q4: Q4Value,
      q5: Q5Value,
    };

    currentUser.push(data);

    console.log(currentUser);

    setDoc(
      doc(db, "users", user.uid),
      { abc: currentUser },
      { merge: true }
    ).then(() => {
      console.log("Running after");
    });
  }

  async function getUserData() {
    if (user && user.uid) {
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());

        getUserLevel();
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
  }

  function getUserLevel() {
    if (user && user.uid) {
      console.log("Getting User Level");
      const unsub = onSnapshot(doc(db, "users", user.uid), (doc) => {
        const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        console.log(source, " data: ", doc.data());

        // Assuming doc.data().abc is the array you're expecting
        // Make sure to check if it exists and is an array
        const userData = doc.data().abc;
        if (Array.isArray(userData)) {
          setYourLevel(userData);
        } else {
          // Handle the case where it's not an array
          console.error('Expected an array for yourLevel, but received:', userData);
          setYourLevel([]); // Reset to an empty array or a default value
        }

        console.log(yourLevel);
      });
    } else {
      console.log("NOT Getting Levels");
    }
  }


  function getTeamLevels() {
    if (user && user.uid) {
      console.log("Getting Levels");
      const unsub = onSnapshot(doc(db, "users", user.uid), (doc) => {
        const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        console.log(source, " data: ", doc.data().abcTeam);

        if (doc.data().abcTeam) {
          setTeamLevels(...[doc.data().abcTeam]);
          setNumberTeam(doc.data().abcTeam.length + yourLevel.length);

          var currentTeam = doc.data().abcTeam;

          let sum = 0;

          currentTeam.forEach((value) => {
            sum += value.score;
          });

          console.log(sum);

          setTeamScoreTotal(sum + userScore);

          var finalCalculation = teamScoreTotal / numberTeam;

          if (finalCalculation <= 5) {
            console.log("A");
            console.log("Total " + finalCalculation);

            setTeamGradeTotal("A");

            setBoxColor("#38B216");
          } else if (finalCalculation > 5 && finalCalculation <= 7) {
            console.log("A-");
            console.log("Total " + finalCalculation);

            setTeamGradeTotal("A-");

            setBoxColor("#38B216");
          } else if (finalCalculation > 7 && finalCalculation <= 9) {
            console.log("B");
            console.log("Total " + finalCalculation);
            setTeamGradeTotal("B");
            setBoxColor("#2192bd");
          } else if (finalCalculation > 9 && finalCalculation <= 10) {
            console.log("B-");
            console.log("Total " + finalCalculation);
            setTeamGradeTotal("B-");
            setBoxColor("#2192bd");
          } else if (finalCalculation >= 10) {
            console.log("C");
            console.log("Total " + finalCalculation);
            setTeamGradeTotal("C");

            setBoxColor("#e43b2c");
          }
        }
      });
    } else {
      console.log("NOT Getting Levels");
    }
  }

  function deleteTeamMember(i) {
    console.log(i);

    var currentTeam = teamLevels;

    console.log("Add Team to Db");

    currentTeam.splice(i, 1);

    console.log(currentTeam);

    setDoc(
      doc(db, "users", user.uid),
      { abcTeam: currentTeam },
      { merge: true }
    ).then(() => {
      console.log("Running after");
      setTQ1Value(0);
      setTQ2Value(0);
      setTQ3Value(0);
      setTQ4Value(0);
      setTQ5Value(0);
      setTQ101(false);
      setTQ102(false);
      setTQ103(false);
      setTQ201(false);
      setTQ202(false);
      setTQ203(false);
      setTQ301(false);
      setTQ302(false);
      setTQ303(false);
      setTQ401(false);
      setTQ402(false);
      setTQ403(false);
      setTQ501(false);
      setTQ502(false);
      setTQ503(false);
      setTeamMemberGrade(null);
      setTeamMemberScore(null);
    });
  }

  function deleteUser() {
    setDoc(doc(db, "users", user.uid), { abc: [] }, { merge: true }).then(
      () => {
        console.log("Running after");
        setQ1Value(0);
        setQ2Value(0);
        setQ3Value(0);
        setQ4Value(0);
        setQ5Value(0);
        setQ101(false);
        setQ102(false);
        setQ103(false);
        setQ201(false);
        setQ202(false);
        setQ203(false);
        setQ301(false);
        setQ302(false);
        setQ303(false);
        setQ401(false);
        setQ402(false);
        setQ403(false);
        setQ501(false);
        setQ502(false);
        setQ503(false);
        setUserGrade(null);
        setUserScore(null);
      }
    );
    closeModal2();
  }

  useEffect(() => {
    getUserData();
  }, [user]);

  useEffect(() => {
    var score = TQ1Value + TQ2Value + TQ3Value + TQ4Value + TQ5Value;

    setTeamMemberScore(score);

    if (score === 5) {
      setTeamMemberGrade("A");
      console.log(teamMemberGrade);
    } else if (score === 6 || score === 7) {
      setTeamMemberGrade("A-");
      console.log(teamMemberGrade);
    } else if (score === 7 || score === 8) {
      setTeamMemberGrade("B");
      console.log(teamMemberGrade);
    } else if (score === 9 || score === 10) {
      setTeamMemberGrade("B-");
      console.log(teamMemberGrade);
    } else if (score > 10) {
      setTeamMemberGrade("C");
      console.log(teamMemberGrade);
    }
  }, [TQ1Value, TQ2Value, TQ3Value, TQ4Value, TQ5Value]);

  useEffect(() => {
    var score = Q1Value + Q2Value + Q3Value + Q4Value + Q5Value;

    setUserScore(score);

    if (score === 5) {
      setUserGrade("A");
      console.log(userGrade);
    } else if (score === 6 || score === 7) {
      setUserGrade("A-");
      console.log(userGrade);
    } else if (score === 7 || score === 8) {
      setUserGrade("B");
      console.log(userGrade);
    } else if (score === 9 || score === 10) {
      setUserGrade("B-");
      console.log(userGrade);
    } else if (score > 10) {
      setUserGrade("C");
      console.log(userGrade);
    }
  }, [Q1Value, Q2Value, Q3Value, Q4Value, Q5Value]);

  useEffect(() => {
    getTeamLevels();
  }, [user, userScore, numberTeam]);

  const styles = {
    radioWhite: {
      border: "10px solid #90DDD0",
    },
    radioPink: {
      border: "10px solid #EF959D",
      backgroundColor: "#EF959D !important",
    },
    radioRed: {
      border: "10px solid #90DDD0",
    },
  };

  if (loading) {
    return (
      <MainContainer>
        <LoadingContainer>
          <AbcLogo src={require("../images/abc_logo.png")}></AbcLogo>
          <StartText>
            An effective journey can only be plotted when you know your starting
            point.
          </StartText>
          <StartText>
            This App will help you identify current A-Player/B- Player/C-Player
            tendencies- first for yourself and then for each of your Team
            Members.
          </StartText>
          <StartText>
            As you complete entering each Team Member into the App, you will see
            a corresponding update to your Team Level Score.
          </StartText>
          <StartText>
            Your current A-B-C Team Level represents your starting point for
            coherently and effectively plotting your journey toward the Ideal
            Team State: functioning as One Team with One Goal having One
            Conversation.
          </StartText>
          <StartText>
            **When using this App, remember: ALL progress begins by telling the
            TRUTH.
          </StartText>

          <StartButton
            onClick={() => {
              console.log("Clicking Button");
              setLoading(false);
              openModal();
            }}
          >
            <p>START HERE</p>
          </StartButton>
        </LoadingContainer>
      </MainContainer>
    );
  } else {
    return (
      <MainContainer>
        <LevelContainer>
          <YourLevelContainer>
            <Title>YOUR LEVEL</Title>
            {yourLevel.map((level, index) => {
              var color = "#e43b2c";

              if (level.score === 5) {
                color = "#38B216";
              } else if (level.score === 6 || level.score === 7) {
                color = "#38B216";
              } else if (level.score === 7 || level.score === 8) {
                color = "#2192bd";
              } else if (level.score === 9 || level.score === 10) {
                color = "#2192bd";
              } else if (level.score > 10) {
                color = "#e43b2c";
              }

              return (
                <LevelCard
                  key={index}
                  style={{
                    borderColor: color,
                    borderType: "solid",
                    borderWidth: 3,
                  }}
                >
                  <CardTitle>{level.name}</CardTitle>

                  <CardGrade>{level.grade}</CardGrade>
                  <CardNumber>{level.score}</CardNumber>
                  {/* <DeleteButtonContainer></DeleteButtonContainer> */}
                  <DeleteButton
                    onClick={() => {
                      deleteUser();
                    }}
                  >
                    X
                  </DeleteButton>
                </LevelCard>
              );
            })}
            {yourLevel.length <= 0 ? (
              <LevelCardAdd>
                <AddUserButton
                  onClick={() => {
                    openModal();
                    //setSelectedIndex();
                  }}
                >
                  +
                </AddUserButton>
              </LevelCardAdd>
            ) : null}
          </YourLevelContainer>

          <TeamLevelContainer>
            <Title>Add Team Member</Title>
            <SubTeamLevelContainer>
              {teamLevels.map((level, index) => {
                var color = "#e43b2c";

                if (level.score === 5) {
                  color = "#38B216";
                } else if (level.score === 6 || level.score === 7) {
                  color = "#38B216";
                } else if (level.score === 7 || level.score === 8) {
                  color = "#2192bd";
                } else if (level.score === 9 || level.score === 10) {
                  color = "#2192bd";
                } else if (level.score > 10) {
                  color = "#e43b2c";
                }

                return (
                  <LevelCard
                    key={index}
                    style={{
                      borderColor: color,
                      borderType: "solid",
                      borderWidth: 3,
                    }}
                  >
                    <DeleteButton
                      onClick={() => {
                        deleteTeamMember(index);
                      }}
                    >
                      X
                    </DeleteButton>
                    <CardTitle>{level.name}</CardTitle>
                    <CardGrade>{level.grade}</CardGrade>
                    <CardNumber>{level.score}</CardNumber>
                  </LevelCard>
                );
              })}
              <LevelCardAdd>
                <AddTeamMemberButton
                  onClick={() => {
                    openModal2();
                    //setSelectedIndex();
                  }}
                >
                  +
                </AddTeamMemberButton>
              </LevelCardAdd>
            </SubTeamLevelContainer>
          </TeamLevelContainer>
          <CalculationsContainer>
            {/* <FunctionContainer>
              <SumContainer>
                <SumValue>{teamScoreTotal}</SumValue>
                <DivisionLine
                  src={require("../images/linedivider.png")}
                ></DivisionLine>
                <SumText>Sum of Team Member Scores</SumText>
              </SumContainer>
              <DivisionContainer>
                <DivideImage
                  src={require("../images/divide.png")}
                ></DivideImage>
              </DivisionContainer>
              <NumberTeamContainer>
                <SumValue>{numberTeam}</SumValue>
                <DivisionLine
                  src={require("../images/linedivider.png")}
                ></DivisionLine>
                <SumText>Number of Team Members</SumText>
              </NumberTeamContainer>
              <EqualContainer>
                <EqualImage src={require("../images/Equal.png")}></EqualImage>
              </EqualContainer>
              <ScoreContainer>
                <SumValue>
                  {parseFloat(Math.round(teamScoreTotal / numberTeam)).toFixed(
                    2
                  )}
                </SumValue>
                <DivisionLine
                  src={require("../images/linedivider.png")}
                ></DivisionLine>
                <SumText>My Team Score</SumText>
              </ScoreContainer>
            </FunctionContainer> */}
            <ScaleContainer>
              <TeamText>MY TEAM LEVEL</TeamText>

              <TeamLevel
                style={{
                  borderColor: boxColor,
                  borderType: "solid",
                  borderWidth: 5,
                }}
              >
                {teamGradeTotal}
              </TeamLevel>
            </ScaleContainer>
          </CalculationsContainer>
        </LevelContainer>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          <ModalContainer>
            <QuizContainer>
              <TitleContainer>
                <QuizTitle>WHAT'S YOUR LEVEL?</QuizTitle>
                <QuizText>Consider each of the following questions</QuizText>
                <QuizText>Choose the response which suits you best</QuizText>
              </TitleContainer>

              <QuestionContainer>
                <QuestionLabel>
                  Your Name:
                  <QuestionOption
                    type="text"
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                  />
                </QuestionLabel>
              </QuestionContainer>

              <QuestionContainer>
                <QuestionTitle>
                  1. When tasks or projects are delegated or assigned to me...
                </QuestionTitle>

                <RadioGroup
                  onChange={(e) => {
                    handleQuestion(e);
                  }}
                >
                  <ReversedRadioButton
                    value="1|1"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    My boss can be confident they will be completed on time and
                    meet or exceed expectations.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="1|2"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    My jobs/tasks will get mostly done on time providing I am
                    given adequate guidance along the way.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="1|3"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    My boss will have concerns that they will not be completed
                    on time and/or will not meet expectations.
                  </ReversedRadioButton>
                </RadioGroup>
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>
                  2. When those above me are recruiting new team members...
                </QuestionTitle>

                <RadioGroup
                  onChange={(e) => {
                    handleQuestion(e);
                  }}
                >
                  <ReversedRadioButton
                    value="2|1"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    They seek others who are a lot like me.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="2|2"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    They tend to look for someone better than me.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="2|3"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    They purposefully look for someone who is NOT like me.
                  </ReversedRadioButton>
                </RadioGroup>
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>
                  3. When I am tasked with doing something I haven't done
                  before...
                </QuestionTitle>

                <RadioGroup
                  onChange={(e) => {
                    handleQuestion(e);
                  }}
                >
                  <ReversedRadioButton
                    value="3|1"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    I teach myself how to do it.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="3|2"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    I need to be guided through the process.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="3|3"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    I need to be shown precisely how to do it and I still
                    sometimes end up doing it incorrectly.
                  </ReversedRadioButton>
                </RadioGroup>
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>
                  4. When I get blocked in my progress toward completing a job
                  or task...
                </QuestionTitle>

                <RadioGroup
                  onChange={(e) => {
                    handleQuestion(e);
                  }}
                >
                  <ReversedRadioButton
                    value="4|1"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    I ask for help.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="4|2"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    I tend to waste time trying to figure it out rather than
                    admit I am struggling.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="4|3"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    I tend to let the project slip and rarely ask for help.
                  </ReversedRadioButton>
                </RadioGroup>
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>
                  5. When it comes to making mistakes...
                </QuestionTitle>

                <RadioGroup
                  onChange={(e) => {
                    handleQuestion(e);
                  }}
                >
                  <ReversedRadioButton
                    value="5|1"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    I am mindful, actively identify error-likely conditions, and
                    readily own and correct my mistakes.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="5|2"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    My mistake rate is ‘average’ and I admit to making mistakes,
                    especially when noticed by others.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="5|3"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    When I make mistakes it is typically the result of process
                    flaws or others’ flawed actions or instructions.
                  </ReversedRadioButton>
                </RadioGroup>
              </QuestionContainer>
              <CalculateButton
                onClick={() => {
                  updateUserData();
                  closeModal();
                }}
              >
                Continue
              </CalculateButton>
            </QuizContainer>
          </ModalContainer>
        </Modal>
        <Modal
          isOpen={modalIsOpen2}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal2}
          contentLabel="Example Modal"
        >
          <ModalContainer>
            <QuizContainer>
              <TitleContainer>
                <QuizTitle>WHAT ARE YOUR TEAM MEMBER LEVELS?</QuizTitle>

                <p>Enter The Name of the Team Member</p>
                <p>
                  Choose the response for each item which most closely describes
                  that member{" "}
                </p>
              </TitleContainer>

              <QuestionContainer>
                <QuestionLabel>
                  Team Member Name:
                  <QuestionOption
                    value={teamMemberName}
                    onChange={(e) => {
                      setTeamMemberName(e.target.value);
                    }}
                  />
                </QuestionLabel>
              </QuestionContainer>

              <QuestionContainer>
                <QuestionTitle>
                  1. When I assign tasks or projects to this team member...
                </QuestionTitle>

                <RadioGroup
                  onChange={(e) => {
                    handleQuestionsTeam(e);
                  }}
                >
                  <ReversedRadioButton
                    value="1|1"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    I can be confident they will be completed on time and meet
                    or exceed my expectations.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="1|2"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    The jobs/tasks will get mostly done on time providing I give
                    them adequate guidance along the way.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="1|3"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    I typically have concerns that they will not be completed on
                    time and/or will not meet my expectations.
                  </ReversedRadioButton>
                </RadioGroup>
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>
                  2. When I am recruiting/considering new team members...
                </QuestionTitle>

                <RadioGroup
                  onChange={(e) => {
                    handleQuestionsTeam(e);
                  }}
                >
                  <ReversedRadioButton
                    value="2|1"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    I seek those who are a lot like this team member.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="2|2"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    I tend to look for someone better than this team member.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="2|3"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    I definitely look for someone who is NOT like this team
                    member.
                  </ReversedRadioButton>
                </RadioGroup>
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>
                  3. When this team member is tasked with doing something they
                  haven't done before...
                </QuestionTitle>

                <RadioGroup
                  onChange={(e) => {
                    handleQuestionsTeam(e);
                  }}
                >
                  <ReversedRadioButton
                    value="3|1"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    They teach themselves how to do it.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="3|2"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    This team member needs to be guided through the process.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="3|3"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    They need to be shown precisely how to do it and they still
                    sometimes end up doing it incorrectly
                  </ReversedRadioButton>
                </RadioGroup>
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>
                  4. When this team member gets blocked in their progress toward
                  completing a job or task...
                </QuestionTitle>

                <RadioGroup
                  onChange={(e) => {
                    handleQuestionsTeam(e);
                  }}
                >
                  <ReversedRadioButton
                    value="4|1"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    They ask for help.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="4|2"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    They tend to waste time trying to figure it out rather than
                    admitting they are struggling
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="4|3"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    They tend to let the project slip and rarely ask for help.
                  </ReversedRadioButton>
                </RadioGroup>
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>
                  5. When it comes to making mistakes...
                </QuestionTitle>

                <RadioGroup
                  onChange={(e) => {
                    handleQuestionsTeam(e);
                  }}
                >
                  <ReversedRadioButton
                    value="5|1"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    They are mindful, actively identify error-likely conditions,
                    and readily own and correct their mistakes.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="5|2"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    Their mistake rate is ‘average’ and they admit to making
                    mistakes, especially when noticed by others.
                  </ReversedRadioButton>
                  <ReversedRadioButton
                    value="5|3"
                    pointColor="#38B216"
                    rootColor="black"
                  >
                    When they make mistakes they quickly point to process flaws
                    or others’ flawed actions or instructions.
                  </ReversedRadioButton>
                </RadioGroup>
              </QuestionContainer>
              <CalculateButton
                onClick={() => {
                  updateTeamData();
                }}
              >
                CONTINUE
              </CalculateButton>
            </QuizContainer>
          </ModalContainer>
        </Modal>
      </MainContainer>
    );
  }
}

export default ABCHome;

const MainContainer = styled.div`
  background-color: #25364c;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const LoadingContainer = styled.div`
  height: 700px;
  width: 500px;
  border-radius: 20px;
  padding: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const AbcLogo = styled.img``;

const StartText = styled.p`
  width: 90%;
  line-height: 1.2;
`;

const StartButton = styled.button`
  width: 90%;
  background-color: #2192bd;
  color: white;
  font-size: 16px;
  border-radius: 10px;
`;

const LevelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const YourLevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55%;
  border-radius: 20px;
  padding: 20px;
  margin: 10px;
  border: 5px solid #38b216;
  background-color: white;
`;

const LevelCard = styled.div`
  width: 200px;
  height: 100px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: 2px solid #2192bd;

  position: relative;
  margin: 10px;
`;

const DeleteButton = styled.button`
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 10px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  top: -10px;
  right: -10px;
`;

const LevelCardAdd = styled.div`
  width: 200px;
  height: 100px;
  border: 2px solid #2192bd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin: 10px;
  overflow: hidden;
`;

const AddTeamMemberButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 60px;
  border-radius: 20px;
  background-color: white;
  color: #2192bd;
  border: 2px solid #38b216;
`;

const AddUserButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 60px;
  border-radius: 20px;
  background-color: white;
  color: #2192bd;
  border: 2px solid #38b216;
`;

const CardTitle = styled.p`
  font-size: 20px;
`;

const CardGrade = styled.p`
  font-size: 30px;
  font-weight: bold;
`;

const CardNumber = styled.p`
  font-size: 20px;
`;

const TeamLevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55%;
  border-radius: 20px;
  padding: 20px;
  margin: 10px;
  border: 5px solid #38b216;
  background-color: white;
`;

const SubTeamLevelContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin: 5px;
  flex-wrap: wrap;
`;

const CalculationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 55%;
  border-radius: 20px;
  padding: 20px;
  margin: 10px;
  border: 5px solid #38b216;
  background-color: white;
`;

const FunctionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

const SumContainer = styled.div`
  height: 200px;
  width: 100px;

  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

const SumValue = styled.p`
  font-size: 60px;
  font-weight: bold;
  margin: 0px;
  text-align: center;
`;

const SumText = styled.p`
  text-align: center;
  font-size: 25px;
  margin: 0px;
  font-weight: bold;
`;

const DivisionContainer = styled.div`
  height: 100px;
  width: 100px;

  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DivideImage = styled.img`
  width: 50px;
  height: 50px;
`;

const DivisionLine = styled.img`
  height: 5px;
  width: 100px;
`;

const NumberTeamContainer = styled.div`
  height: 200px;
  width: 100px;

  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

const EqualContainer = styled.div`
  height: 100px;
  width: 100px;

  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EqualImage = styled.img`
  width: 50px;
  height: 30px;
`;

const ScoreContainer = styled.div`
  height: 200px;
  width: 200px;

  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

const ScaleContainer = styled.div`
  display: flex;

  flex-container: row;
  align-items: center;
  justify-content: space-around;
`;

const TeamText = styled.p`
  font-size: 40px;
  font-weight: bold;

  text-align: center;
`;

const TeamLevel = styled.p`
  font-size: 60px;
  font-weight: bold;
  margin: 20px;
  border: 5px solid blue;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #25364c;
  margin: 0px;
  border-radius: 20px;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 10px;
  border-radius: 20px;
  padding: 10px;
  width: 90%;
  align-items: center;
  justify-content: center;
`;

const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const QuestionContainer = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  background-color: white;
  margin: 10px;
  border-radius: 20px;
  padding: 10px;
`;

const QuestionTitle = styled.h1``;

const QuestionLabel = styled.label`
  padding: 5px;
  margin: 5px;
`;

const QuestionOption = styled.input`
  margin: 10px;
`;

const QuizTitle = styled.h1``;
const QuizText = styled.p`
  font-size: 18px;
`;

const OptionContainer = styled.div`
  margin: 10px;
`;

const CalculateButton = styled.button`
  width: 90%;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #2192bd;
  color: white;
  font-size: 20px;
  border-radius: 10px;
`;

const Title = styled.p`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`;

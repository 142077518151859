import "react-buzzfeed-quiz/lib/styles.css";

// import {
//   Legend,
//   PolarAngleAxis,
//   PolarGrid,
//   PolarRadiusAxis,
//   Radar,
//   RadarChart,
//   ResponsiveContainer,
// } from "recharts";
import React, { Component, useRef, useState } from "react";
import {
  auth,
  registerWithEmailAndPasswordMarketing,
  signInWithGoogle,
} from "../firebase.js";

import BackgroundImage from "../images/taxi.png";
import { BuzzFeedQuiz } from "react-buzzfeed-quiz";
import FirstResultImage from "../images/taxi.png";
import ProfilePhoto from "../images/taxi.png";
import PropTypes from "prop-types";
import ResponseImage from "../images/taxi.png";
import { ResponsiveRadar } from "@nivo/radar";
import SecondResultImage from "../images/taxi.png";
import gridImage from "../images/WTLAY-grid-with-labels.png";
import logo from "../images/WTLAY-title-graphic.png";
import quadrant1Image from "../images/Quadrant-01.png";
import quadrant2Image from "../images/Quadrant-02.png";
import quadrant3Image from "../images/Quadrant-03.png";
import quadrant4Image from "../images/Quadrant-04.png";
import styled from "styled-components";

function QuestionsMarketing() {
  const [answers, setAnswers] = useState([]);
  const command = useRef(0);
  const control = useRef(0);
  const collaborate = useRef(0);
  const connect = useRef(0);

  const [commandTotal, setCommandTotal] = useState(0);
  const [controlTotal, setControlTotal] = useState(0);
  const [collaborateTotal, setCollaborateTotal] = useState(0);
  const [connectTotal, setConnectTotal] = useState(0);

  const [showForm, setShowForm] = useState(false);
  const [showGraph, setShowGraph] = useState(false);

  const [renderQuadrant1, setRenderQuadrant1] = useState(false);
  const [renderQuadrant2, setRenderQuadrant2] = useState(false);
  const [renderQuadrant3, setRenderQuadrant3] = useState(false);
  const [renderQuadrant4, setRenderQuadrant4] = useState(false);

  // Custom Form State

  const [occupation, setOccupation] = useState("");

  const [companySize, setCompanySize] = useState(0);

  const [name, setName] = useState("");

  const [email, setEmail] = useState("");

  const [phone, setPhone] = useState(0);

  const [dominantQuadrant, setDominantQuadrant] = useState(0);

  const data = [
    {
      subject: "Collaborate",
      Total: collaborateTotal,
      fullMark: 25,
      color: "#fff",
    },
    { subject: "Connect", Total: connectTotal, fullMark: 25 },
    { subject: "Control", Total: controlTotal, fullMark: 25 },
    { subject: "Command", Total: commandTotal, fullMark: 25 },
  ];

  const theme = {
    fontSize: 2,
    textColor: "#25364c",
    axis: {
      textColor: "#eee",
      fontSize: "25px",
      tickColor: "#eee",
    },
    grid: {
      stroke: "#888",
      strokeWidth: 1,
    },
    legend: { text: { fill: "#fff" } },
  };

  function renderData() {
    console.log("Render Data");
    setShowForm(true);
    //setShowGraph(true);

    answers.forEach((answer, index) => {
      console.log(answer);
      // console.log(answer.question);
      // console.log(answer.resultID);

      if (
        answer.question === 0 ||
        answer.question === 2 ||
        answer.question === 4 ||
        answer.question === 6
      ) {
        command.current = command.current + answer.resultID;
        console.log("QUESTION " + answer.question);

        // Handle Command
        console.log("Handle Command");
        console.log("command: " + command.current);
        console.log("control: " + control.current);
        console.log("collaborate: " + collaborate.current);
        console.log("connect: " + connect.current);
      } else if (
        answer.question === 1 ||
        answer.question === 3 ||
        answer.question === 5 ||
        answer.question === 7
      ) {
        control.current = control.current + answer.resultID;
        console.log("QUESTION " + answer.question);
        console.log("Handle Control");
        console.log("command: " + command.current);
        console.log("control: " + control.current);
        console.log("collaborate: " + collaborate.current);
        console.log("connect: " + connect.current);
      } else if (
        answer.question === 8 ||
        answer.question === 10 ||
        answer.question === 12 ||
        answer.question === 14
      ) {
        collaborate.current = collaborate.current + answer.resultID;
        console.log("QUESTION " + answer.question);
        console.log("Handle Collaborate");
        console.log("command: " + command.current);
        console.log("control: " + control.current);
        console.log("collaborate: " + collaborate.current);
        console.log("connect: " + connect.current);
      } else if (
        answer.question === 9 ||
        answer.question === 11 ||
        answer.question === 13 ||
        answer.question === 15
      ) {
        connect.current = connect.current + answer.resultID;
        if (answer.question === 15) {
          setCommandTotal(command.current);
          setControlTotal(control.current);
          setCollaborateTotal(collaborate.current);
          setConnectTotal(connect.current);

          var quadrant1 = control.current + command.current;
          var quadrant2 = control.current + connect.current;
          var quadrant3 = command.current + collaborate.current;
          var quadrant4 = collaborate.current + connect.current;

          console.log(
            "Quadrant1 " + quadrant1,
            "Quadrant2 " + quadrant2,
            "Quadrant3 " + quadrant3,
            "Quadrant4 " + quadrant4
          );

          if (
            quadrant1 > quadrant2 &&
            quadrant1 > quadrant3 &&
            quadrant1 > quadrant4
          ) {
            console.log("Quadrant 1 largest");
            setRenderQuadrant1(true);
            setDominantQuadrant(1);
          } else if (
            quadrant2 > quadrant1 &&
            quadrant2 > quadrant3 &&
            quadrant2 > quadrant4
          ) {
            console.log("Quadrant 2 largest");
            setRenderQuadrant2(true);
            setDominantQuadrant(2);
          } else if (
            quadrant3 > quadrant1 &&
            quadrant3 > quadrant2 &&
            quadrant3 > quadrant4
          ) {
            console.log("Quadrant 3 largest");
            setRenderQuadrant3(true);
            setDominantQuadrant(3);
          } else if (
            quadrant4 > quadrant1 &&
            quadrant4 > quadrant2 &&
            quadrant4 > quadrant3
          ) {
            console.log("Quadrant 4 largest");
            setRenderQuadrant4(true);
            setDominantQuadrant(4);
          } else {
            setDominantQuadrant(0);
          }
          window.scrollTo(0, 0);
        }
        console.log("QUESTION " + answer.question);
        console.log("Handle Connect");
        console.log("command: " + command.current);
        console.log("control: " + control.current);
        console.log("collaborate: " + collaborate.current);
        console.log("connect: " + connect.current);
      }
    });
  }

  var quadrant = (
    <QuadrantsContainer>
      <ChartContainer>
        <ResponsiveRadar
          data={data}
          keys={["Total"]}
          indexBy="subject"
          maxValue={25}
          valueFormat=">-.2f"
          margin={{ top: 40, right: 81, bottom: 40, left: 90 }}
          borderColor={{ from: "color", modifiers: [] }}
          gridLevels={0}
          gridShape="linear"
          gridLabelOffset={25}
          dotColor={{ theme: "background" }}
          dotBorderWidth={2}
          //colors={{ scheme: "nivo" }}
          colors={["#FFFF00", "#222222"]}
          fillOpacity={0.9}
          motionConfig="wobbly"
          theme={theme}
          legends={[
            {
              anchor: "top-left",
              direction: "column",
              translateX: -50,
              translateY: -40,
              itemWidth: 80,
              itemHeight: 20,
              itemTextColor: "#fff",
              symbolSize: 12,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#fff",
                  },
                },
              ],
            },
          ]}
        />
      </ChartContainer>
      <MainTitle>
        <UnderlinedText>
          Your Predominant Leadership Style is…in the Middle
        </UnderlinedText>
      </MainTitle>
      <BottomContainerResult>
        <RegularTextWhite>Well, Isn't That Interesting...</RegularTextWhite>
        <RegularTextWhite>
          Your responses indicate that your Leadership Styles are spread evenly
          between all (4) quadrants.
        </RegularTextWhite>
        <RegularTextWhite>
          To receive your FULL Report, including Conditions & Considerations for
          the future, as well as how your Predominant Leadership Style compares
          with others, complete the form below.
        </RegularTextWhite>
        <ResultFormQuestionContainer>
          <ResultFormQuestion>
            <QuestionTitle>Name</QuestionTitle>
            <QuestionOption
              type="text"
              className="login__textBox"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder=""
            />
          </ResultFormQuestion>
          <ResultFormQuestion>
            <QuestionTitle>Email</QuestionTitle>

            <QuestionOption
              type="text"
              className="login__textBox"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder=""
            />
          </ResultFormQuestion>
          <ResultFormQuestion>
            <QuestionTitle>Phone</QuestionTitle>

            <QuestionOption
              type="text"
              className="login__textBox"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder=""
            />
          </ResultFormQuestion>
          <ShowMyResultsButton
            onClick={() => {
              // Create firebase account and add to db using data

              var password = "wtlay123";

              console.log(name);
              console.log(email);
              console.log(password);
              console.log(phone);
              console.log(dominantQuadrant);
              console.log(occupation);
              console.log(companySize);

              registerWithEmailAndPasswordMarketing(
                name,
                email,
                password,
                phone,
                dominantQuadrant,
                occupation,
                companySize
              );
            }}
          >
            Show My Full Results
          </ShowMyResultsButton>
        </ResultFormQuestionContainer>
      </BottomContainerResult>
    </QuadrantsContainer>
  );

  if (renderQuadrant1) {
    quadrant = (
      <QuadrantsContainer>
        <MainTitle>
          <UnderlinedText>Your Predominant Leadership Style</UnderlinedText>
        </MainTitle>
        <QuadrantContainer>
          <TopContainer>
            {/* <GraphicContainer>
              <QuadrantImage src={quadrant1Image}></QuadrantImage>
            </GraphicContainer> */}
            <ChartContainer>
              <ResponsiveRadar
                data={data}
                keys={["Total"]}
                indexBy="subject"
                maxValue={25}
                valueFormat=">-.2f"
                margin={{ top: 40, right: 81, bottom: 40, left: 90 }}
                borderColor={{ from: "color", modifiers: [] }}
                gridLevels={0}
                gridShape="linear"
                gridLabelOffset={25}
                dotColor={{ theme: "background" }}
                dotBorderWidth={2}
                //colors={{ scheme: "nivo" }}
                colors={["#FFFF00", "#222222"]}
                fillOpacity={0.9}
                motionConfig="wobbly"
                theme={theme}
                legends={[
                  {
                    anchor: "top-left",
                    direction: "column",
                    translateX: -50,
                    translateY: -40,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemTextColor: "#fff",
                    symbolSize: 12,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#fff",
                        },
                      },
                    ],
                  },
                ]}
              />
            </ChartContainer>
            <TitleContainer>
              <TitleQuadrant>Quadrant I: Conventional BOSS</TitleQuadrant>
            </TitleContainer>
          </TopContainer>
          <BottomContainerResult>
            <RegularText>
              To receive your FULL Report, including Conditions & Considerations
              for the future, as well as how your Predominant Leadership Style
              compares with others, complete the form below.
            </RegularText>

            <ResultFormQuestionContainer>
              <ResultFormQuestion>
                <QuestionTitle>Name</QuestionTitle>
                <QuestionOption
                  type="text"
                  className="login__textBox"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder=""
                />
              </ResultFormQuestion>
              <ResultFormQuestion>
                <QuestionTitle>Email</QuestionTitle>

                <QuestionOption
                  type="text"
                  className="login__textBox"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder=""
                />
              </ResultFormQuestion>
              <ResultFormQuestion>
                <QuestionTitle>Phone</QuestionTitle>

                <QuestionOption
                  type="text"
                  className="login__textBox"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder=""
                />
              </ResultFormQuestion>
              <ShowMyResultsButton
                onClick={() => {
                  // Create firebase account and add to db using data

                  var password = "wtlay123";

                  console.log(name);
                  console.log(email);
                  console.log(password);
                  console.log(phone);
                  console.log(dominantQuadrant);
                  console.log(occupation);
                  console.log(companySize);

                  registerWithEmailAndPasswordMarketing(
                    name,
                    email,
                    password,
                    phone,
                    dominantQuadrant,
                    occupation,
                    companySize
                  );
                }}
              >
                Show My Full Results
              </ShowMyResultsButton>
            </ResultFormQuestionContainer>
          </BottomContainerResult>
        </QuadrantContainer>
        <TitleStyle></TitleStyle>
      </QuadrantsContainer>
    );
  } else if (renderQuadrant2) {
    quadrant = (
      <QuadrantsContainer>
        <MainTitle>
          <UnderlinedText>Your Predominant Leadership Style</UnderlinedText>
        </MainTitle>
        <QuadrantContainer>
          <TopContainer>
            {/* <GraphicContainer>
              <QuadrantImage src={quadrant2Image}></QuadrantImage>
            </GraphicContainer> */}
            <ChartContainer>
              <ResponsiveRadar
                data={data}
                keys={["Total"]}
                indexBy="subject"
                maxValue={25}
                valueFormat=">-.2f"
                margin={{ top: 40, right: 81, bottom: 40, left: 90 }}
                borderColor={{ from: "color", modifiers: [] }}
                gridLevels={0}
                gridShape="linear"
                gridLabelOffset={25}
                dotColor={{ theme: "background" }}
                dotBorderWidth={2}
                //colors={{ scheme: "nivo" }}
                colors={["#FFFF00", "#222222"]}
                fillOpacity={0.9}
                motionConfig="wobbly"
                theme={theme}
                legends={[
                  {
                    anchor: "top-left",
                    direction: "column",
                    translateX: -50,
                    translateY: -40,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemTextColor: "#fff",
                    symbolSize: 12,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#fff",
                        },
                      },
                    ],
                  },
                ]}
              />
            </ChartContainer>
            <TitleContainer>
              <TitleQuadrant>Quadrant II: Committee Director</TitleQuadrant>
            </TitleContainer>
          </TopContainer>
          <BottomContainerResult>
            <RegularText>
              To receive your FULL Report, including Conditions & Considerations
              for the future, as well as how your Predominant Leadership Style
              compares with others, complete the form below.
            </RegularText>

            <ResultFormQuestionContainer>
              <ResultFormQuestion>
                <QuestionTitle>Name</QuestionTitle>
                <QuestionOption
                  type="text"
                  className="login__textBox"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder=""
                />
              </ResultFormQuestion>
              <ResultFormQuestion>
                <QuestionTitle>Email</QuestionTitle>

                <QuestionOption
                  type="text"
                  className="login__textBox"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder=""
                />
              </ResultFormQuestion>
              <ResultFormQuestion>
                <QuestionTitle>Phone</QuestionTitle>

                <QuestionOption
                  type="text"
                  className="login__textBox"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder=""
                />
              </ResultFormQuestion>
              <ShowMyResultsButton
                onClick={() => {
                  // Create firebase account and add to db using data

                  var password = "wtlay123";

                  console.log(name);
                  console.log(email);
                  console.log(password);
                  console.log(phone);
                  console.log(dominantQuadrant);
                  console.log(occupation);
                  console.log(companySize);

                  registerWithEmailAndPasswordMarketing(
                    name,
                    email,
                    password,
                    phone,
                    dominantQuadrant,
                    occupation,
                    companySize
                  );
                }}
              >
                Show My Full Results
              </ShowMyResultsButton>
            </ResultFormQuestionContainer>
          </BottomContainerResult>
        </QuadrantContainer>
      </QuadrantsContainer>
    );
  } else if (renderQuadrant3) {
    quadrant = (
      <QuadrantsContainer>
        <MainTitle>
          <UnderlinedText>Your Predominant Leadership Style</UnderlinedText>
        </MainTitle>

        <QuadrantContainer>
          <TopContainer>
            {/* <GraphicContainer>
              <QuadrantImage src={quadrant3Image}></QuadrantImage>
            </GraphicContainer> */}
            <ChartContainer>
              <ResponsiveRadar
                data={data}
                keys={["Total"]}
                indexBy="subject"
                maxValue={25}
                valueFormat=">-.2f"
                margin={{ top: 40, right: 81, bottom: 40, left: 90 }}
                borderColor={{ from: "color", modifiers: [] }}
                gridLevels={0}
                gridShape="linear"
                gridLabelOffset={25}
                dotColor={{ theme: "background" }}
                dotBorderWidth={2}
                //colors={{ scheme: "nivo" }}
                colors={["#FFFF00", "#222222"]}
                fillOpacity={0.9}
                motionConfig="wobbly"
                theme={theme}
                legends={[
                  {
                    anchor: "top-left",
                    direction: "column",
                    translateX: -50,
                    translateY: -40,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemTextColor: "#fff",
                    symbolSize: 12,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#fff",
                        },
                      },
                    ],
                  },
                ]}
              />
            </ChartContainer>
            <TitleContainer>
              <TitleQuadrant>Quadrant III: Charismatic General</TitleQuadrant>
            </TitleContainer>
          </TopContainer>
          <BottomContainerResult>
            <RegularText>
              To receive your FULL Report, including Conditions & Considerations
              for the future, as well as how your Predominant Leadership Style
              compares with others, complete the form below.
            </RegularText>

            <ResultFormQuestionContainer>
              <ResultFormQuestion>
                <QuestionTitle>Name</QuestionTitle>
                <QuestionOption
                  type="text"
                  className="login__textBox"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder=""
                />
              </ResultFormQuestion>
              <ResultFormQuestion>
                <QuestionTitle>Email</QuestionTitle>

                <QuestionOption
                  type="text"
                  className="login__textBox"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder=""
                />
              </ResultFormQuestion>
              <ResultFormQuestion>
                <QuestionTitle>Phone</QuestionTitle>

                <QuestionOption
                  type="text"
                  className="login__textBox"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder=""
                />
              </ResultFormQuestion>
              <ShowMyResultsButton
                onClick={() => {
                  // Create firebase account and add to db using data

                  var password = "wtlay123";

                  console.log(name);
                  console.log(email);
                  console.log(password);
                  console.log(phone);
                  console.log(dominantQuadrant);
                  console.log(occupation);
                  console.log(companySize);

                  registerWithEmailAndPasswordMarketing(
                    name,
                    email,
                    password,
                    phone,
                    dominantQuadrant,
                    occupation,
                    companySize
                  );
                }}
              >
                Show My Full Results
              </ShowMyResultsButton>
            </ResultFormQuestionContainer>
          </BottomContainerResult>
        </QuadrantContainer>
      </QuadrantsContainer>
    );
  } else if (renderQuadrant4) {
    quadrant = (
      <QuadrantsContainer>
        <MainTitle>
          <UnderlinedText>Your Predominant Leadership Style</UnderlinedText>
        </MainTitle>

        <QuadrantContainer>
          <TopContainer>
            {/* <GraphicContainer>
              <QuadrantImage src={quadrant4Image}></QuadrantImage>
            </GraphicContainer> */}
            <ChartContainer>
              <ResponsiveRadar
                data={data}
                keys={["Total"]}
                indexBy="subject"
                maxValue={25}
                valueFormat=">-.2f"
                margin={{ top: 40, right: 81, bottom: 40, left: 90 }}
                borderColor={{ from: "color", modifiers: [] }}
                gridLevels={0}
                gridShape="linear"
                gridLabelOffset={25}
                dotColor={{ theme: "background" }}
                dotBorderWidth={2}
                //colors={{ scheme: "nivo" }}
                colors={["#38B216", "#222222"]}
                fillOpacity={0.9}
                motionConfig="wobbly"
                theme={theme}
                legends={[
                  {
                    anchor: "top-left",
                    direction: "column",
                    translateX: -50,
                    translateY: -40,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemTextColor: "#fff",
                    symbolSize: 12,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#fff",
                        },
                      },
                    ],
                  },
                ]}
              />
            </ChartContainer>
            <TitleContainer>
              <TitleQuadrant>Quadrant IV: Truly Human LEADER</TitleQuadrant>
            </TitleContainer>
          </TopContainer>
          <BottomContainerResult>
            <RegularText>
              To receive your FULL Report, including Conditions & Considerations
              for the future, as well as how your Predominant Leadership Style
              compares with others, complete the form below.
            </RegularText>

            <ResultFormQuestionContainer>
              <ResultFormQuestion>
                <QuestionTitle>Name</QuestionTitle>
                <QuestionOption
                  type="text"
                  className="login__textBox"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder=""
                />
              </ResultFormQuestion>
              <ResultFormQuestion>
                <QuestionTitle>Email</QuestionTitle>

                <QuestionOption
                  type="text"
                  className="login__textBox"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder=""
                />
              </ResultFormQuestion>
              <ResultFormQuestion>
                <QuestionTitle>Phone</QuestionTitle>

                <QuestionOption
                  type="text"
                  className="login__textBox"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder=""
                />
              </ResultFormQuestion>
              <ShowMyResultsButton
                onClick={() => {
                  // Create firebase account and add to db using data

                  var password = "wtlay123";

                  console.log(name);
                  console.log(email);
                  console.log(password);
                  console.log(phone);
                  console.log(dominantQuadrant);
                  console.log(occupation);
                  console.log(companySize);

                  registerWithEmailAndPasswordMarketing(
                    name,
                    email,
                    password,
                    phone,
                    dominantQuadrant,
                    occupation,
                    companySize
                  );
                }}
              >
                Show My Full Results
              </ShowMyResultsButton>
            </ResultFormQuestionContainer>
          </BottomContainerResult>
        </QuadrantContainer>
      </QuadrantsContainer>
    );
  }

  return (
    <div>
      {showGraph ? (
        <ResultContainer>
          <LogoContainer>
            <LogoImage src={logo}></LogoImage>
          </LogoContainer>
          <StepContainer>
            <Step>
              <StepTitle>
                <StepTitleText>STEP 2</StepTitleText>
              </StepTitle>
              <StepTextContainer>
                <StepText>
                  Consider your current leadership traits on the graph below.
                </StepText>
                <StepText>
                  The shape in the middle represents your current general
                  tendencies toward Command, Control, Connection, and
                  Collaboration.
                </StepText>
                <StepText>
                  In which current quadrant do most of your tendencies lie?
                </StepText>
              </StepTextContainer>
            </Step>
          </StepContainer>
          <ChartContainer>
            <ResponsiveRadar
              data={data}
              keys={["Total"]}
              indexBy="subject"
              maxValue={25}
              valueFormat=">-.2f"
              margin={{ top: 40, right: 81, bottom: 40, left: 90 }}
              borderColor={{ from: "color", modifiers: [] }}
              gridLevels={0}
              gridShape="linear"
              gridLabelOffset={25}
              dotColor={{ theme: "background" }}
              dotBorderWidth={2}
              //colors={{ scheme: "nivo" }}
              colors={["#bdd7ee", "#222222"]}
              fillOpacity={0.9}
              motionConfig="wobbly"
              theme={theme}
              legends={[
                {
                  anchor: "top-left",
                  direction: "column",
                  translateX: -50,
                  translateY: -40,
                  itemWidth: 80,
                  itemHeight: 20,
                  itemTextColor: "#fff",
                  symbolSize: 12,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#fff",
                      },
                    },
                  ],
                },
              ]}
            />
          </ChartContainer>
          <StepContainer>
            <Step>
              <StepTitle>
                <StepTitleText>STEP 3</StepTitleText>
              </StepTitle>
              <StepTextContainer>
                <StepText>
                  Now that you’ve identified your predominant/natural Quadrant,
                  consider the following as you move forward into your future as
                  a LEADER{" "}
                </StepText>
                <StepText>
                  <StrongText> Note 1:</StrongText>
                  Your statement responses were likely based on your current
                  frame of mind (mindset & perception). This should represent
                  your natural tendencies during normal levels of stress &
                  activity. During acute levels of stress and/or shifting
                  conditions you may shift Quadrants. And this can be a GOOD
                  thing. For example, if you’re the Leader in a nuclear power
                  plant control room during an emergency, Command & Control are
                  necessary & appropriate, even though this might not be your
                  natural style.
                </StepText>
                <StepText>
                  <StrongText> Note 2:</StrongText> Into the future, you will
                  want to move your natural leadership style more and more
                  toward being a Truly Human Leader (into Quadrant IV- Connect
                  &Collaborate). Societal, generational, and technical trends
                  (think AI) are converging in a way that’s creating a much
                  greater need for (and responsiveness to) Quadrant IV
                  LEADERSHIP.
                </StepText>
                <StepText>
                  <StrongText> Note 3:</StrongText> Characteristics and
                  suggestions for each LEADERSHIP Quadrant are provided below.
                  Links to additional insights & information, including the What
                  Type Leader Are You? Training Video, are provided below.
                </StepText>
              </StepTextContainer>
            </Step>
          </StepContainer>
          {quadrant}
        </ResultContainer>
      ) : null}
      {showForm ? (
        <div>{quadrant}</div>
      ) : (
        <QuizContainer>
          <LogoContainer>
            <LogoImage src={logo}></LogoImage>
          </LogoContainer>
          <DescriptionContainer>
            <DescriptionText>
              Discover YOUR current predominant Leadership traits & how they'll
              IMPACT YOUR FUTURE!
            </DescriptionText>
          </DescriptionContainer>

          <CustomQuestionsContainer>
            <QuestionContainer>
              <QuestionTitle>I am a...</QuestionTitle>

              <QuestionSelection
                value={companySize}
                onChange={(e) => setOccupation(e.target.value)}
              >
                <SelectionOption value="Senior Leader">
                  Senior Leader
                </SelectionOption>
                <SelectionOption value="Business Owner">
                  Business Owner
                </SelectionOption>
                <SelectionOption value="Department Head/Mid-Level Manager">
                  Department Head/Mid-Level Manager
                </SelectionOption>
                <SelectionOption value="Front Line Supervisor">
                  Front Line Supervisor
                </SelectionOption>
                <SelectionOption value="Individual Contributor">
                  Individual Contributor
                </SelectionOption>
              </QuestionSelection>
            </QuestionContainer>
            <QuestionContainer>
              <QuestionTitle>Company Size</QuestionTitle>
              <QuestionSelection
                value={companySize}
                onChange={(e) => setCompanySize(e.target.value)}
              >
                <SelectionOption value="Less than 10">
                  Less Than 10
                </SelectionOption>
                <SelectionOption value="11-50">11-50</SelectionOption>
                <SelectionOption value="51-100">51-100</SelectionOption>
                <SelectionOption value="101-250">101-250</SelectionOption>
                <SelectionOption value="251-500">251-500</SelectionOption>
                <SelectionOption value=">500">>500</SelectionOption>
              </QuestionSelection>
            </QuestionContainer>
          </CustomQuestionsContainer>

          <StepContainer>
            <Step>
              <StepTitle>
                <StepTitleText>STEP 1</StepTitleText>
              </StepTitle>
              <StepTextContainer>
                <StepText>
                  Rate each of the following statements from [Strongly Agree] to
                  [Strongly Disagree]
                </StepText>
              </StepTextContainer>
            </Step>
          </StepContainer>

          <BuzzFeedQuiz
            title={null}
            description={null}
            byline={false}
            bylineAuthor={"Your name"}
            bylineAuthorLink={"www.yourlink.com"}
            bylineAuthorLinkOpenInNewTab={true}
            bylineAuthorTagline={"Your author tagline goes here"}
            bylineAvatarImageSrc={ProfilePhoto}
            autoScroll={true}
            onRestart={() =>
              alert("This alert was triggered by the onRestart prop!")
            }
            onResult={(r) => {
              console.log(r);
              console.log("Ready");
            }}
            onAnswerSelection={(questionIndex, answerIndex, resultID) => {
              var newAnswers = answers;

              var answer = {
                question: questionIndex,
                answerIndex: answerIndex,
                resultID: resultID,
              };

              newAnswers.splice(questionIndex, 1, answer);

              setAnswers(newAnswers);

              if (questionIndex === 15) {
                renderData();
              }
            }}
            facebookShareButton={true}
            facebookShareLink={"www.yourlink.com"}
            twitterShareButton={true}
            twitterShareLink={"www.yourlink.com"}
            copyShareButton={true}
            copyShareLink={"This text was copied using the copyShareLink prop."}
            questions={[
              {
                question:
                  "Most Team Members feel insecure about their work and need direction",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Team Members need to be supervised closely or they're not likely to do their work",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Effective Leaders give orders and clarify procedures",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Team Member motivation comes primarily through direct external reward and/or punishment",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Effective Leaders are specific, detailed, and direct",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "The Leader is chief judge of Team Member achievements",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "It is imperative for Team Members to know who is in charge",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Leadership requires constant monitoring and feedback",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "In complex situations, Leaders should give Team Members as much latitude as possible to 'fix' things on their own",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "As a rule, Leaders, Supervisors, and Individual Contributors should work together collectively to make plans and evaluate work",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Team Members should be actively engaged in the decision-making process",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "The Leader's role is to bring appropriate people/teams/departments together to generate ideas and solve problems",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Team Members are basically competent and want to do a good job",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "It is much better to share vision and guidance across teams and departments (rather than caring about who gets the credit)",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Highest levels of Reliability, Efficiency, Productivity, and Safety come through spontaneous cooperation of Team Members",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
              {
                question:
                  "Diverse input maximizes the potential for and magnitude of success",
                backgroundColor: "#2192bd",
                answerArrangement: "row",
                fontColor: "#fff",
                style: { color: "red" },
                answers: [
                  {
                    answer: "Strongly Agree",
                    resultID: 5,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Agree",
                    resultID: 4,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Neutral",
                    resultID: 3,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Disagree",
                    resultID: 2,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                  {
                    answer: "Strongly Disagree",
                    resultID: 1,
                    backgroundColor: "red",
                    fontColor: "rgb(215, 215, 215)",
                  },
                ],
              },
            ]}
            results={[
              {
                title: "Your first result title goes here",
                description: "Your first result description goes here",
                resultImageSrc: FirstResultImage,
                imageAttribution: "Your photo attribution text goes here",
                resultID: 0,
              },
              {
                title: "Your second result title goes here",
                description: "Your second result description goes here",
                resultImageSrc: SecondResultImage,
                resultID: 1,
              },
              {
                title: "Your third result title goes here",
                description: "Result images and attributions are optional!",
                resultID: 2,
              },
              {
                title: "Your third result title goes here",
                description: "Result images and attributions are optional!",
                resultID: 3,
              },
              {
                title: "Your third result title goes here",
                description: "Result images and attributions are optional!",
                resultID: 4,
              },
              {
                title: "Your third result title goes here",
                description: "Result images and attributions are optional!",
                resultID: 5,
              },
            ]}
          />
        </QuizContainer>
      )}
    </div>
  );
}

export default QuestionsMarketing;

const QuizContainer = styled.div``;

const ResultContainer = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StepContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px;
`;

const Step = styled.div`
  border: 2px solid #38b216;
  width: 55%;
  display: flex;
  flex-direction: row;
`;

const StepTitle = styled.div`
  width: 25%;
  background-color: #25364c;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepTitleText = styled.h1`
  text-align: center;
  color: white;
`;

const StepTextContainer = styled.div`
  padding: 5px;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StepText = styled.p`
  width: 100%;
  font-size: 18px;
  padding: 5px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
`;

const LogoImage = styled.img`
  width: 35%;
  height: 35%;
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DescriptionText = styled.h1`
  width: 60%;
  font-size: 25px;
  text-align: center;
  font-weight: 400;
`;

const QuadrantsContainer = styled.div`
  padding: 5px;
  background-color: #25364c;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QuadrantContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #2192bd;
  border-radius: 25px;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  width: 80%;
`;

const GraphicContainer = styled.div`
  width: 50%;
`;

const QuadrantImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const TitleContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExplanationContainer = styled.div`
  width: 70%;
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  overflow: hidden;
`;

const BottomContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;
const BottomContainerResult = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 80%;
  align-self: center;
`;

const Title = styled.h1`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: black;
`;

const MainTitle = styled.h1`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
`;

const TitleStyle = styled.h1`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
`;

const Subtitle = styled.h1`
  font-size: 20px;
  color: #2192bd;
`;

const RegularText = styled.p`
  font-size: 18px;
`;

const RegularTextWhite = styled.p`
  color: white;
  text-align: center;
`;

const ChartContainer = styled.div`
  height: 275px;
  width: 500px;
  display: flex;
  align-items: center;
  margin: 20px;
  background-color: #25364c;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${gridImage});
`;

const GridImage = styled.img``;

const UnderlinedText = styled.u``;
const RegularList = styled.ul``;
const RegularListItem = styled.li`
  margin-top: 10px;
  list-style: none;

  '&:before': {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: red; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  },
`;
const EmphasizedText = styled.em``;
const StrongText = styled.strong``;

const CustomQuestionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const QuestionContainer = styled.div`
  width: 50%;
  height: 90px;
  display: flex;
  background-color: #25364c;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  border: 2px solid #38b216;
  margin: 5px;
`;

const QuestionTitle = styled.h1`
  width: 100%;
  color: white;
  font-size: 25px;
  text-align: center;
  margin-bottom: 2px;
`;

const QuestionOption = styled.input`
  margin: 5px;
`;

const QuestionSelection = styled.select`
  height: 30px;
  font-size: 20px;
  text-align: center;
`;

const SelectionOption = styled.option``;

const SubtitleLink = styled.a`
  color: white;
`;

const TitleQuadrant = styled.p`
  width: 80%;
  color: #2192bd;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
`;

const SubtitleLinkQuadrant = styled.a`
  padding: 5px;
  font-size: 18px;
  font-weight: 500;
  margin: 10px;
`;

const ResultFormQuestionContainer = styled.div`
  width: 40%;
  background-color: #25364c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 5px;
`;

const ResultFormQuestion = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const ShowMyResultsButton = styled.button`
  margin: 20px;
  width: 70%;
  height: 50px;
  background-color: #2192bd;
  font-size: 18px;
  color: white;
  border-radius: 10px;
`;
